@charset "UTF-8";
.GavadonArea {
  position: relative;
  height: 500px;
  width: 600px;
}
.GavadonAreaModal {
  position: relative;
  height: 450px;
  width: 100%;
  cursor: pointer;
  z-index: 2;
}
.GavadonAreaUI {
  background-color: #dddddd;
  border-radius: 3px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
  position: absolute;
  display: flex;
  right: 0;
  margin: 5px;
}
.GavadonAreaUI button {
  background-color: transparent;
  border: none;
}
.GavadonAreaUI button:hover {
  background-color: #c5c5c5;
}
.GavadonAreaUI button:active {
  background-color: #dddddd;
}
.GavadonAreaUI button img {
  height: 20px;
  width: 20px;
  margin-bottom: -5px;
}
.GavadonEulerUI {
  background-color: #dddddd;
  border-radius: 3px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
  position: absolute;
  display: flex;
  flex-flow: column;
  left: 0;
  margin: 5px;
  padding: 5px;
  font-size: 12px;
}
.GavadonEulerUI .Title {
  font-size: 12px;
}
.GavadonEulerUI .Rotate {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.GavadonEulerUI .Rotate .Title {
  margin-right: 5px;
}
.GavadonEulerUI .Rotate input {
  font-size: 13px;
  width: 80px;
  height: 15px;
}
.GavadonEulerUI .Rotate :focus {
  outline: none;
}
.GavadonRotateMode {
  background-color: #dddddd;
  border-radius: 3px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
  position: absolute;
  right: 0;
  margin: 5px;
  margin-top: 165px;
  padding: 5px;
}
.GavadonRotateMode .Title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
}
.GavadonEulerUI1 {
  /* background-color: #ffffff;
  border: 2px solid #ff8f22;
  border-radius: 14px;
  position: absolute;
  display: block;
  top:200px ;
  left: 580px;
  margin: 5px;
  padding: 5px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  z-index: 2; */
  
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}
.themeSwitch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GavadonEulerUI1.show {
  background-color: #ffffff;
  border: 2px solid #ff8f22;
  border-radius: 14px;
  position: absolute;
  /* display: flex; */
  display: block;
  /* top:200px ; */
  top: 40px;
  /* right: 0px; */
  left: 580px;
  margin: 5px;
  padding: 5px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  opacity: 10;
}
