@charset "UTF-8";
/* -------------------------------------
 * modelNumberSpecEdit.css
 * 型番仕様ページ用
------------------------------------- */
.modelNoSpecEditBlock {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.modelNoSpecEditBlock .pageTitle {
  margin-bottom: 40px;
}
/* -----------------------------
 * フォーム
 ------------------------------*/
 .modelNoSpecEditFormBlock {
  width: 48%;
}
.modelNoSpecEditFormAccordionContent {
  margin-bottom: 16px;
}
.modelNoSpecEditFormToggle {
  display: none;
}
.modelNoSpecEditFormToggleLabel {
  display: block;
  width: 100%;
  height: 40px;
  padding-left: 1em;
  font-size: 16px;
  font-weight: bold;
  color: #1c6ecd;
  line-height: 40px;
  background-color: #e6eef8;
  border-bottom: 2px solid #1c6ecd;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
}
.modelNoSpecEditFormToggleLabel:after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../image/common/icon_toggle_up_blue.svg) no-repeat right center;
  position: absolute;
  top: calc(50% - 8px);
  right: 12px;
}
.modelNoSpecEditFormToggle:checked + .modelNoSpecEditFormToggleLabel:after {
  background: url(../image/common/icon_toggle_down_blue.svg) no-repeat right center;
}
.modelNoSpecEditFormToggleLabel,
.modelNoSpecEditFormToggleContentBlock {
  position: relative;
  z-index: 0;
  /*-webkit-backface-visibility: hidden;*/
  /*backface-visibility: hidden;*/
  /*transform: translateZ(0);*/
  /*transition: all 0.3s;*/
}
.modelNoSpecEditFormToggleContentBlock {
  height: auto;
  margin-bottom: 10px;
  padding: 12px 0;
  overflow: hidden;
}
.modelNoSpecEditFormToggle:checked
  + .modelNoSpecEditFormToggleLabel
  + .modelNoSpecEditFormToggleContentBlock {
  height: 0;
  padding: 0;
  transition: all 0.3s;
}
.modelNoSpecEditFormToggleContent {
  margin-bottom: 12px;
}
.modelNoSpecEditFormInputBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.modelNoSpecEditFormToggleContent label {
  display: inline-block;
  width: 30%;
  padding: 8px 0;
  color: #333333;
  font-size: 14px;
}
.modelNoSpecEditFormToggleContent label p {
  font-size: 12px;
  padding-top: 4px;
}
.modelNoSpecEditFormToggleContent label p a {
  color: #0856ba;
  text-decoration: underline;
}

.modelNoSpecEditFormToggleContent input {
  display: inline-block;
  width: 60%;
  padding: 8px;
  font-size: 14px;
  color: #333333;
  border: 1px solid #dfe1e3;
}
.modelNoSpecEditFormToggleContent textarea {
  display: inline-block;
  width: 60%;
  padding: 8px;
  font-size: 14px;
  color: #333333;
  border: 1px solid #dfe1e3;
  box-sizing: border-box;
}
.modelNoSpecEditFormToggleContent select {
  width: 60%;
  max-height: 40px;
  font-size: 14px;
}
.modelNoSpecEditFormToggleContent input:disabled,
.modelNoSpecEditFormToggleContent textarea:disabled {
  background: #f0eeef;
}
/* 3Dデータアップロード */
.modelNoSpecEditFormFileUploadBlock {
  width: 100%;
}
.modelNoSpecEditFormFileUploadArea {
  padding: 24px 0;
  margin-bottom: 16px;
  text-align: center;
  border: 1px dotted #1C6ECD;
  border-radius: 3px;
}
.modelNoSpecEditFormFileUploadAreaDisabled {
  background-color: #F0EEEF;
}
.modelNoSpecEditFormFileUploadAreaImage {
  margin-bottom: 8px;
}
.modelNoSpecEditFormFileUploadAreaPara1 {
  margin-bottom: 8px;
  font-size: 14px;
  color: #8B8B8B;
  text-align: center;
}
.modelNoSpecEditFormFileUploadAreaPara2 {
  font-size: 12px;
  color: #8B8B8B;
}
.modelNoSpecEditFormFileUploadAreaLink {
  color: #1C6ECD;
}
.modelNoSpecEditFormFileUploadedName {}
.modelNoSpecEditFormFileUploadedLink {
  padding-right: 24px;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  text-decoration: none;
  background: url(../image/common/icon_close_gray.svg) no-repeat right center;
}
.modelNoSpecEditFormFileUploadedLinkDisalbed {
  padding-right: 24px;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  text-decoration: none;
}

/* 造形可否判定結果 */
.modelNoSpecEditFormCheckTag {
  display: inline;
  margin-left: 4px;
  padding: 4px 12px;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
  border-radius: 3px;
}
.modelNoSpecEditFormCheckTagOk {
  background: #00b862;
}
.modelNoSpecEditFormCheckTagPartiallyOk {
  background: #ff8f22;
}
.modelNoSpecEditFormCheckTagNg {
  background: #d70a30;
}
.modelNoSpecEditFormCheckDetail {
  margin-top: -12px;
  border: 1px solid #f0eeef;
  padding: 16px;
}
.modelNoSpecEditFormCheckPara {
  margin-bottom: 8px;
  padding-left: 2em;
  font-size: 12px;
}
.modelNoSpecEditFormCheckParaOk {
  background: url(../image/common/icon_check_ok_green.svg) no-repeat 0% 8%;
}
.modelNoSpecEditFormCheckParaPartiallyOk {
  background: url(../image/common/icon_check_ok_orange.svg) no-repeat 0% 8%;
}
.modelNoSpecEditFormCheckParaNg {
  background: url(../image/common/icon_check_ng_red.svg) no-repeat 0% 8%;
}
.modelNoSpecEditFormCheckLinkOk,
.modelNoSpecEditFormCheckLinkPartiallyOk,
.modelNoSpecEditFormCheckLinkNg {
  font-weight: bold;
}
.modelNoSpecEditFormCheckLinkOk {
  color: #00b862;
}
.modelNoSpecEditFormCheckLinkPartiallyOk {
  color: #ff8f22;
}
.modelNoSpecEditFormCheckLinkNg {
  color: #d70a30;
}
/* 試算基本情報 */
.modelNoSpecEditFormCalculationList {
  list-style: none;
  padding-left: 12px;
}
.modelNoSpecEditFormCalculationListItem {
  display: flex;
  padding: 0 12px 0 28px;
  border-bottom: 1px solid #f0eeef;
  line-height: 54px;
}
.modelNoSpecEditFormCalculationListItem:nth-child(1) {
  background: url(../image/common/illust_triangle.svg) no-repeat left center;
}
.modelNoSpecEditFormCalculationListItem:nth-child(2) {
  background: url(../image/common/illust_rectangle.svg) no-repeat left center;
}
.modelNoSpecEditFormCalculationListItem:nth-child(3) {
  background: url(../image/common/illust_cube.svg) no-repeat left center;
}
.modelNoSpecEditFormCalculationListItem:nth-child(4) {
  background: url(../image/common/illust_clock.svg) no-repeat left center;
  border-bottom: none;
}
.modelNoSpecEditFormCalculationTitle {
  width: 20%;
  font-size: 14px;
  font-weight: bold;
}
.modelNoSpecEditFormCalculationDetail {
  width: 80%;
  font-size: 12px;
  height: 54px;
}

.modelNoSpecEditFormCalculationDetail span {
  font-size: 10px;
  vertical-align: super;
}

/* オプション */
.modelNoSpecEditFormOptionSettingBlock {
}
.modelNoSpecEditFormOptionSettingBlock textarea {
  height: 6em;
}
/* 追加要望 */
.modelNoSpecEditFormOptionSettingAddRequest {
  display: flex;
  justify-content: space-between;
  margin: 24px 0 12px;
  padding: 12px;
  border-top: 1px solid #f0eeef;
}
.modelNoSpecEditFormOptionSettingAddRequestPara {
  width: 300px;
  font-size: 12px;
  line-height: 1.4em;
}
/* 列追加UI */
.modelNoSpecEditFormInputAddRowBlock {
  width: 60%;
}
.modelNoSpecEditFormInputAddRowInner {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.modelNoSpecEditFormInputAddRowInner select {
  width: 60%;
}
.modelNoSpecEditFormInputAddRowAmountBlock {
  width: 40%;
}
.modelNoSpecEditFormInputAddRowAmountBlock select {
  margin-left: 8px;
  height: 40px;
  font-size: 14px;
}
.modelNoSpecEditFormInputAddRowAmountBlock select:disabled {
  background: #f0eeef;
}
.modelNoSpecEditFormInputAddRow {
  width: 100%;
}
.modelNoSpecEditFormInputAddRowLink {
  display: block;
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  color: #1c6ecd;
  text-align: center;
  background: url(../image/common/icon_circle_plus_blue.svg) no-repeat 35% 50%;
  background-color: #e6eef8;
  border-radius: 4px;
}
.modelNoSpecEditFormInputRowDeleteLink {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 12px 0 0 8px;
  background: url(../image/common/icon_circle_cross_gray.svg) no-repeat top center;
}
/* マウスオーバーサブ情報 */
.modelNoSpecEditFormSubInfoBlock {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  background: rgba(0, 86, 186, 0.9);
}
.modelNoSpecEditFormSubInfoRow {
  display: flex;
  border-bottom: 1px solid #508cd0;
  font-size: 12px;
  color: #ffffff;
}
.modelNoSpecEditFormSubInfoTitle {
  padding: 8px;
  border-right: 1px solid #508cd0;
  font-weight: bold;
  flex: 1;
}
.modelNoSpecEditFormSubInfoDetail {
  flex: 2;
  padding: 8px;
}
/* トグルスイッチ */
.toggleSwitchLayer {
  width: 100%;
  background-color: #ff8f22;
  transition: 0.3s ease all;
  z-index: 1;
}
.toggleSwitchInner {
  position: relative;
  top: 50%;
  width: 80px;
  height: 36px;
  border-radius: 80px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}
.toggleSwitchCheckbox {
  position: relative;
  width: 100% !important;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.toggleSwitchKnobs {
  z-index: 2;
}
.toggleSwitchKnobs,
.toggleSwitchLayer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.toggleSwitchKnobs:before,
.toggleSwitchKnobs:after,
.toggleSwitchKnobs span {
  position: absolute;
  top: 4px;
  width: 20px;
  height: 10px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  border-radius: 40px;
  transition: 0.3s ease all;
}
.toggleSwitchKnobs:before,
.toggleSwitchKnobs:after {
  color: #ffffff;
  z-index: 1;
  font-family: 'Arial';
}
.toggleSwitchKnobs:before {
  content: 'ON';
  left: 8px;
}
.toggleSwitchKnobs:after {
  content: 'OFF';
  right: 8px;
}
.toggleSwitchKnobs span {
  width: 25px;
  left: 42px;
  background-color: #ffffff;
  z-index: 2;
  box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
.toggleSwitchCheckbox:checked + .toggleSwitchKnobs span {
  left: 4px;
}
.toggleSwitchCheckbox:checked ~ .toggleSwitchLayer {
  background-color: #8b8b8b;
}


/* 追加ファイルアップロード */
.modelNoSpecEditFormOptionSettingFileUploadInner {
  width: 60%;
}
.modelNoSpecEditFormOptionSettingFileUploadBlock {
  width: 100%;
  margin-bottom: 12px;
  padding: 48px 16px 16px;
  border-radius: 3px;
  border: 1px dotted #1c6ecd;
  box-sizing: border-box;
}
.modelNoSpecEditFormOptionSettingFileUploadBlockDisalbed {
  background: url(../image/common/illust_cloud_gray.svg) no-repeat 50% 20%;
  background-color: #f0eeef;
}
.modelNoSpecEditFormOptionSettingFileUploadBlockActive {
  background: url(../image/common/illust_cloud_blue.svg) no-repeat 50% 20%;
  background-color: #ffffff;
}
.modelNoSpecEditFormOptionSettingFileUploadPara {
  color: #8b8b8b;
  font-size: 12px;
  text-align: center;
}
.modelNoSpecEditFormOptionSettingFileUploadPara {
  color: #8B8B8B;
  font-size: 12px;
  text-align: center;
}
/*指示書アップロード*/
.directionFileUploadedName {
}

/* 単価・価格 */
.modelNoSpecEditFormUnitPriceBlock {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f0eeef;
  margin-bottom: 12px;
  border-radius: 2px;
}
.modelNoSpecEditFormUnitPriceBlockDisabled {
  background: #f0eeef;
  border: none;
}
.modelNoSpecEditFormUnitPriceTitle {
  padding: 16px;
  padding-top: 24px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background: #1c6ecd;
  border-radius: 2px 0 0 2px;
}
.modelNoSpecEditFormUnitPriceDetail {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}
.modelNoSpecEditFormUnitPriceDeliveryPara {
  margin-top: 11.5px;
  font-size: 12px;
  color: #333333;
}
.modelNoSpecEditFormUnitPriceTenModelsMessagePara {
  margin-top: 0px;
  font-size: 12px;
  color: #333333;
}
.modelNoSpecEditFormUnitPriceTenModelsInfoPara {
  font-size: 14px;
  color: #333333;
}
.modelNoSpecEditFormUnitPricePara {
  margin-top: 7px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  font-family: 'Arial';
}
.modelNoSpecEditFormUnitPriceTenModelsPara {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  font-family: 'Arial';
}
/* -----------------------------
 * ビューワー
 ------------------------------*/
 .modelNoSpecEditViewerBlock {
  width: 48%;
}
.modelNoSpecEditViewerInner {
  position: sticky;
  top: 144px;
}
.modelNoSpecEditViewerArea {
  position: relative;
}
.modelNoSpecEditViewerArea img {
  width: 100%;
}
.modelNoSpecEditViewerUi {
  width: 332px;
  position: absolute;
  top: 12px;
  right: 12px;
}
.modelNoSpecEditViewerUi img {
  width: 100%;
}
.modelNoSpecEditViewerBtnBlock .btnOrangeEdit {
  margin-bottom: 12px;
}
.modelNoSpecEditViewerBtnThumb,
.modelNoSpecEditViewerBtnCapture {
  display: block;
  font-size: 12px;
  color: #1c6ecd;
  text-decoration: underline;
}
.modelNoSpecEditViewerBtnThumb:hover,
.modelNoSpecEditViewerBtnCapture:hover {
  opacity: 0.7;
}
.modelNoSpecEditViewerBtnThumb {
  position: absolute;
  left: 12px;
  bottom: 16px;
  background: url(../image/common/icon_camera_blue.svg) no-repeat 5% 50%;
  background-color: #ffffff;
}
.modelNoSpecEditViewerBtnCapture {
  padding-left: 20px;
  background: url(../image/common/icon_download_blue.svg) no-repeat 5% 50%;
  background-color: #ffffff;
  font-weight: bold;
}
.modelNoSpecEditViewerBtnCaptureDisabled {
  display: block;
  padding-left: 20px;
  font-size: 12px;
  font-weight: bold;
  background: url(../image/common/icon_capture_download_gray.svg) no-repeat 5% 50%;
  color: #8b8b8b;
  text-decoration: underline;
}

.modelNoSpecEditViewerFormBlock {
  display: flex;
  width: 100%;
  margin-top: 16px;
  justify-content: space-between;
}
.modelNoSpecEditViewerFormTextarea {
  width: 60%;
  padding: 8px;
  border: 1px solid #dfe1e3;
  font-size: 13px;
  min-height: 56px;
}
/* -----------------------------
 * モーダル
 ------------------------------*/
/* 材料比較 */
.modelNoSpecEditModalCompareMaterial .modalWindowBlock {
  width: 50%;
  min-width: 500px;
  max-width: 800px;
}
.modelNoSpecEditModalCompareMaterialContents {
}
.modelNoSpecEditModalCompareMaterialList {
  list-style: none;
}
.modelNoSpecEditModalCompareMaterialListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #f0eeef;
}
.modelNoSpecEditModalCompareMaterialListItem:hover {
  background: #e6eef8;
  cursor: pointer;
}
.modelNoSpecEditModalCompareMaterialListItem:last-child {
  border-bottom: none;
}
.modelNoSpecEditModalCompareMaterialTitle {
  font-size: 14px;
  font-weight: bold;
  flex: 3;
}
.modelNoSpecEditModalCompareMaterialDetail {
  flex: 2;
}
.modelNoSpecEditModalCompareMaterialDetailPrice {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Arial';
  text-align: right;
}
.modelNoSpecEditModalCompareMaterialDelivery {
  font-size: 12px;
  text-align: right;
}
.modelNoSpecEditModalCompareMaterialDetailLink {
  display: block;
  width: 70px;
  height: 28px;
  margin: 0 0 0 20px;
  padding: 0 8px;
  background: #ffffff;
  color: #1c6ecd;
  font-size: 12px;
  text-decoration: none;
  text-align: center;
  line-height: 28px;
  border: 1px solid #1c6ecd;
  border-radius: 30px;
  transition: all 0.5s ease-out;
}
.modelNoSpecEditModalCompareMaterialDetailLink:hover {
  color: #ffffff;
  background: #1c6ecd;
}

/* 材料詳細 */
.modelNoSpecEditModalMaterialDetail .modalWindowBlock {
  width: 50%;
  min-width: 500px;
  max-width: 800px;
}
.modelNoSpecEditModalMaterialDetail .modalWindowContents {
  padding: 24px;
}
.modelNoSpecEditModalMaterialDetailTitle {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
  color: #1c6ecd;
}
.modelNoSpecEditModalMaterialDetailContents {
  max-height: 60vh;
  overflow: auto;
}
.modelNoSpecEditModalMaterialDetailBlock {
  display: flex;
  justify-content: space-between;
}
.modelNoSpecEditModalMaterialDetailImage {
  width: 236px;
  margin-right: 24px;
}
.modelNoSpecEditModalMaterialDetails {
  width: 40%;
}
.modelNoSpecEditModalMaterialDetailSubTitle {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: bold;
}
.modelNoSpecEditModalMaterialDetailPara {
  font-size: 14px;
  line-height: 1.6em;
  white-space: pre-line;
}
/* 造形姿勢編集 */
.modelModelingPostureEditWindow {
  display: flex;
  min-width: 1000px;
}
.modelModelingPostureEditWindowCloseParent {
  position: absolute;
  direction: rtl;
  width: 100%;
}
.modelModelingPostureEditWindowClose {
  position: fixed;
  top: auto;
  margin: 0;
  width: 64px;
  height: 64px;
  padding-top: 36px;
  display: block;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background: url(../image/common/icon_cross_white.svg) no-repeat 50% 38%;
  box-sizing: border-box;
  background-color: #1c6ecd;
  border-style: none;
}
.flexModelingPostureEdit {
  /*div*/
  display: flex;
  flex-wrap: nowrap;
  height: 559px;
}
.modelModelingPostureInfoBlock {
  /*div*/
  width: 40%;
  min-width: 480px;
}
.modelModelingPostureImageBlock {
  /*div*/
  display: flex;
  flex-flow: column;
  /* align-items: center; */
}
h2.modelNoSpecEditFormLabel {
  /*h2*/
  display: block;
  width: 100%;
  height: 40px;
  padding-left: 1em;
  font-size: 16px;
  font-weight: bold;
  color: #1c6ecd;
  line-height: 40px;
  background-color: #e6eef8;
  border-bottom: 2px solid #1c6ecd;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
}
.modelNoSpecEditFormCheckTagNG {
  /*div*/
  background: #d70a30;
}
.modelNoSpecEditFormCheckParaNG {
  /*p*/
  background: url('../image/common/icon_check_ng_red.svg') no-repeat 0% 8%;
}
.modelModelingPostureInfoBlock .modelNoSpecEditFormCheckDetail {
  /*div*/
  height: 258px;
  overflow: auto;
  border: none;
}
.modelModelingPostureInfoBlock .modelNoSpecEditFormToggleContentBlock {
  /*div*/
  height: auto;
  margin: 0;
}
.modelModelingPostureInfoBlock .modelNoSpecEditFormToggleContent {
  /*div*/
  margin-bottom: 0px;
}
.btnOrangeConfirm {
  /*button*/
  display: inline-block;
  padding: 8px 20px;
  min-width: 140px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #ff8f22;
  border-radius: 24px;
  border: none;
  background-color: #ffffff;
  border: 2px solid #ff8f22;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.btnOrangeConfirm:hover {
  background-color: #ff8f22;
  color: #ffffff;
}
.btnOrangeConfirm:disabled {
  color: #8b8b8b;
  border: 2px solid #f0eeef;
  background-color: #f0eeef;
}
.ModelingPostureResetLink {
  /*a*/
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 24px;
  transition: all 0.5s ease-out;
}
.ModelingPostureResetLink:hover {
  /*a*/
  opacity: 0.7;
}
.ModelingPostureResetLink img {
  /*img*/
  margin-right: 4px;
}
.modelModelingPostureImageButtonBlock {
  /*div*/
  padding: 12px 24px;
}
.recommendedBrowserTable {
  width: 460px;
  margin: 16px 0 24px;
}
.recommendedBrowserTable caption {
  text-align: left;
  font-size: 14px;
  color: #333333;
  margin-bottom: 8px;
}
.recommendedBrowserTable th {
  background-color: #e6eef8;
  font-size: 14px;
  font-weight: normal;
  color: #333333;
  height: 48px;
}
.recommendedBrowserTable td {
  width: 172px;
  text-align: center;
  padding: 15px 0;
  color: #333333;
  font-size: 14px;
}
.recommendedBrowserTable th,
.recommendedBrowserTable td {
  border: 1px solid #f0eeef;
}
/*フォーム確認画面*/
.pageTitleDetailText{
  margin:40px 0;
  color: #333;
  font-size: 14px;
}

table.modelNoSpecAddressConfirmation{
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #333;
}

table.modelNoSpecAddressConfirmation th{
  width:152px;
}

table.modelNoSpecAddressConfirmation th,table.modelNoSpecAddressConfirmation td{
  border-bottom: 1px solid #F0EEEF;
  height: 24px;
  padding: 20px 24px;
}

a.changeShippingInfoLink {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  margin: 32px 0;
  padding-left: 16px;
  color: #1c6ecd;
  background: url('../image/common/icon_edit_blue.svg') no-repeat 0 50%;
  transition: all 0.5s ease-out;
}
a.changeShippingInfoLink:hover {
  opacity: 0.7;
}
.modelNoSpecButtonBlock {
  display: flex;
  justify-content: space-between;
}

.contactPerson,
.formalQuoteRequest {
  width: 49%;
  text-align: center;
  color: #333;
  padding: 24px 0;
  font-size: 14px;
}
.contactPerson {
  background-color: #f8fbff;
}

.formalQuoteRequest{
  background-color: #FFFAF2;
}

.contactPerson h3,.formalQuoteRequest h3{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
.btnContactPerson {
  display: inline-block;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #1C6ECD;
  border-radius: 24px;
  background-color: #fff;
  border: 2px solid #1C6ECD;
  box-sizing: border-box;
  transition: all .5s ease-out;
  min-width: 200px;
  margin-top: 16px;
}
.btnContactPerson:hover{
  color: #fff;
    background-color: rgba(0, 0, 0, 0);
  background-color: #1C6ECD;
  min-width: 200px;
}

.formalQuoteRequest select{
    height: 40px;
    max-width: 230px;
}
.formalQuoteRequest .btnOrangeCommit{
  margin-top: 16px;
}
/*造形姿勢について*/
.aboutModelModelingPostureButtonBlock{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}
.btnReadmoreBlue{
  display: inline-block;
  padding: 8px 40px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border-radius: 24px;
  border: none;
  background-color: #1C6ECD;
  border: 2px solid #1C6ECD;
  box-sizing: border-box;
  transition: all .5s ease-out;
}
.btnReadmoreBlue:hover{
  color: #1C6ECD;
  background-color: #fff;
  border: 2px solid #1C6ECD;
}