@charset "UTF-8";
/* -------------------------------------
 * clientRegistrationForm.css
 * 顧客画面_登録フォーム・完了画面用
------------------------------------- */
/*input 横幅*/
.registrationFormContentInput .clientRegistrationFormInput100 {
  width: 98%;
}
.registrationFormContentInput .clientRegistrationFormInput50 {
  width: 30%;
  margin-right: 12px;
}
.registrationFormContentInput #post_code01,
#post_code02 {
  width: 64px;
  margin: 0 4px 8px;
}
.registrationFormContentInput #address01 {
  margin-bottom: 8px;
}
/*フォーム下線*/
.registrationFormBlock .lastBorderNone {
  border-bottom: none;
}
/*仮登録完了画面*/
.pageTitleDetailText {
  margin: 40px 0;
  color: #333;
  font-size: 14px;
}
.btnOrangeCommit {
  padding: 8px 24px;
  min-width: 160px;
}
table.quotationRequestAddressConfirmation {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #333;
}
table.quotationRequestAddressConfirmation th {
  width: 152px;
}
table.quotationRequestAddressConfirmation th,
table.quotationRequestAddressConfirmation td {
  border-bottom: 1px solid #f0eeef;
  height: 24px;
  padding: 20px 24px;
}
.clientInformationConfirmationButtonAreaText {
  /*p*/
  font-size: 14px;
  margin: 40px 0 24px;
  color: #333;
}
.attentionRedText {
  color: #d70a30;
  font-size: 12px;
}

/*登録フォーム*/
.registrationFormBlock {
  margin-bottom: 24px;
  color: #333;
}
.registrationFormContent {
  display: flex;
}
.registrationFormContentLabel {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 16px 8px 24px;
  background: #e6eef8;
  border-left: 2px solid #1c6ecd;
  border-bottom: 1px solid #fff;
  width: 250px;
}
.registrationFormContentInput {
  padding: 14px 24px;
  width: 100%;
  background: #f8fbff;
  border-bottom: 1px dotted#F0EEEF;
}

.registrationFormContentLabel .formHelpInfomationLink {
  margin-left: 10px;
}

.registrationFormContentLabel label {
  display: inline-block;
  width: 11em;
  font-size: 14px;
  font-weight: bold;
}
.registrationFormContentAddition {
  display: flex;
  margin: 8px 0;
}
.registrationFormContentAdditionTitle {
  padding: 24px 16px 8px 24px;
  background: #f8fbff;
  border-left: 2px solid #1c6ecd;
  border-bottom: 1px solid #fff;
  min-width: 184px;
}
.registrationFormContentArea {
  padding: 24px;
  width: 100%;
  background: #f8fbff;
}
.registrationFormContentAdditionTitle label {
  font-size: 18px;
  font-weight: bold;
}
.registrationFormContentText {
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
}
.registrationFormContentLink {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: bold;
}
.registrationFormContentLink a {
  transition: all 0.5s ease-out;
}
.registrationFormContentLink a:link,
.registrationFormContentLink a:visited {
  color: #1c6ecd;
}
.registrationFormContentLink a:hover {
  opacity: 0.7;
}

.registrationFormContentAdditionCheckbox {
  display: flex;
  align-items: center;
  letter-spacing: 0.12em;
  font-size: 14px;
  margin-bottom: 8px;
}
.registrationFormContentAdditionCheckbox input {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

/*住所取得ボタン*/
.addressAcquisitionbtn {
  margin: 0 auto;
  padding: 4px 8px;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  border: 1px solid #1c6ecd;
  border-radius: 30px;
  white-space: nowrap;
  background: #1c6ecd;
  transition: all 0.5s ease-out;
}
.addressAcquisitionbtn:hover {
  color: #1c6ecd;
  background: #ffffff;
  border: 1px solid #1c6ecd;
}

@media screen and (max-width: 768px) {
  .mainContentsBlock {
    width: 100vw;
    max-width: unset;
    min-width: unset;
  }

  .pageTitleBlock, .pageTitleDetailText, .clientInformationRegistrationButtonArea, .clientInformationConfirmationButtonArea {
    margin: 0 24px !important;
  }

  /* UserRegistRation */
  .registrationFormContent {
    flex-direction: column;
  }

  .registrationFormContentLabel {
    width: 100%;
    box-sizing: border-box;
  }

  .registrationFormContentLabel label {
    width: auto;
  }

  .registrationFormContentInput {
    width: 100%;
    box-sizing: border-box;
  }

  .registrationFormContentAddition {
    flex-direction: column;
  }

  .registrationFormContentAdditionTitle {
    width: 100%;
    box-sizing: border-box;
  }

  .registrationFormContentArea {
    width: 100%;
    box-sizing: border-box;
  }

  .formHelpInfomationDetail2 {
    width: 150px;
  }

  /* UserRegistRationConfirm */
  table.quotationRequestAddressConfirmation tr {
    display: flex;
    flex-flow: column;
  }

  table.quotationRequestAddressConfirmation th, table.quotationRequestAddressConfirmation td {
    width: 100%;
    height: auto;
    min-height: 62px;
    box-sizing: border-box;
  }

  /* UserRegistConfirm */
  .clientConfirmPageContainer .pageTitleBlock,
  .clientConfirmPageContainer .pageTitleDetailText,
  .clientConfirmPageContainer .confirmCodeForm {
    margin: 24px;
  }

  .clientConfirmPageContainer .confirmCodeForm {
    margin-top: 24px;
    flex-flow: column;
  }

  .confirmCodeInputContent {
    margin: 16px 0 !important;
  }

  .confirmCodeInputContent input {
    width: 100%;
    box-sizing: border-box;
  }
}