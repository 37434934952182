@charset "UTF-8";

/* -------------------------------------
 * top.css
 *
 * トップページのスタイル
------------------------------------- */
/* トップ共通パーツ */
.textOrange {
  color: #FF8F22;
}

.textBlue {
  color: #1C6ECD;
}

/*コンバージョンボタン*/
.conversionButton a.btnOrangeSignup {
  display: block;
  width: 262px;
  padding: 22px 48px;
  margin: 0 auto 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 100px;
  background: #FF8F22;
  color: #FFF;
  text-align: center;
  /* font-family: Meiryo; */
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 109.091% */
  text-decoration: none;
  text-align: center;
  border: 2px solid #FF8F22;
  transition: 1.0s;
}

.conversionButton a.btnOrangeSignup span {
  font-size: 15px;
  line-height: 14px;
  /* 100% */
}

.conversionButton a.btnOrangeSignup:hover {
  color: #FF8F22;
  background: #fff;
}

.conversionButton a.loginButton {
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  line-height: 20px;
}


/*新着お知らせ*/
.headerInformationBlock {
  margin-top: 56px;
  padding: 16px;
  background-color: #EFF4F8;
  border-top: #F0EEEF 1px solid;
  border-bottom: #F0EEEF 1px solid;
}

.headerInformationContents {
  display: flex;
  justify-content: flex-start;
  height: 19px;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 83px;
}

.headerInformationContents .headerInfoSwiperSlide {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

.headerInformationContents .titleText {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0.1em;
  margin-right: 24px;
  min-width: 70px;
}

.headerInformationContents .prev,
.headerInformationContents .next {
  margin-right: 8px;
}

:root {
  --swiper-navigation-size: 20px !important;
}

.headerInformationContents .prev,
.headerInformationContents .next {
  margin-right: 8px;
}

.headerInformationContents .date {
  font-size: 12px;
  color: #333333;
  line-height: 24px;
  margin-left: 16px;
}

.headerInformationContents .headerInfoCategoryLabelInformation {
  background-color: #1c6ecd;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  text-decoration: none;
  height: 19px;
  padding: 0 6px;
  border-radius: 4px;
  margin: 0 8px;
  white-space: nowrap;
}

.headerInformationContents .headerInfoCategoryLabel {
  background-color: #ff8f22;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  text-decoration: none;
  height: 19px;
  padding: 0 6px;
  border-radius: 4px;
  margin: 0 8px;
  white-space: nowrap;
}

.headerInfoArticleBlock {
  display: flex;
}

.headerInfoArticleTitle {
  text-align: left;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #333333;
  text-decoration: none;
  word-wrap: break-word;
}

.headerInformationContents .headerInfoCategoryLabel {
  background-color: #FF8F22;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  text-decoration: none;
  height: 19px;
  padding: 0 6px;
  border-radius: 4px;
  line-height: 21px;
  margin: 0 8px;
}

.headerInfoArticleTitele {
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #333333;
  text-decoration: none;
}

/*サブ ナビゲーション*/
.subNaviBlock {
  position: absolute;
  top: 32px;
  right: 40px;
}

.subNaviBlock ul.subNaviContents {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.subNaviBlock .subNaviContents li {
  display: block;
  background: url(../image/top/top_icon_chevron_down_black.svg) no-repeat right center;
  padding-right: 20px;
  margin-right: 32px;
}

.subNaviBlock .subNaviContents li a {
  text-decoration: none;
  color: #333;
  text-align: center;
  /* font-family: Meiryo; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: 0.56px;
  transition: 0.5s;
}

.subNaviBlock .subNaviContents li:hover {
  background: url(../image/top/top_icon_chevron_down_blue.svg) no-repeat right center;
}

.subNaviBlock .subNaviContents a:hover {
  color: #1C6ECD;
  text-decoration: underline;
}


/* FirstView */
.mobileLoginButtonContainer {
  display: none;
}

.topFirstViewContents {
  position: relative;
  padding-top: 109px;
  background: url(../image/top/top_bg_fv.jpg) 300px 100% / cover no-repeat;
  padding-bottom: 80px;
}

.topFirstViewBlock {
  width: 1160px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

/* Text */
.topFirstViewTextBlock {}

.topFirstViewTextBlock .subheading {
  color: #1C6ECD;
  /* font-family: Meiryo; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.18px;
}

.topFirstViewTextBlock .subheading span {
  margin: 0 12px;
}

.topFirstViewTextH1 {
  color: #1C6ECD;
  /* font-family: Meiryo; */
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
}

.topFirstViewTextH2 {
  color: #8B8B8B;
  /* font-family: Meiryo; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-top: -24px;
  margin-bottom: 32px;
}

.topFirstViewTextBlock .subtext {
  line-height: 30px;
  letter-spacing: 0.06em;
  color: #333333;
  /* font-family: Meiryo; */
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 166.667% */
  letter-spacing: 0.72px;
}

.topFirstViewButtonArea {
  margin-top: 32px;
}

.topFirstViewButtonPara {
  text-align: center;
}

.topFirstViewButtonPara a {
  color: #333;
  /* font-family: Meiryo; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.84px;
  text-decoration-line: underline;
  transition: 1.0s;
  position: relative;
  z-index: 10;
}

.topFirstViewButtonPara a:hover {
  color: #1C6ECD;
}

/* Movie */
.topFirstViewMovieBlock {
  position: relative;
}

.mobile-video {
  display: none;
  /* スマホ版の動画を非表示に */
}

.topFirstViewMovieBlock .topFirstViewMovieIframe iframe {
  margin-bottom: 32px;
  border-radius: 10px;
  border: 6px solid #FFF;
  box-shadow: 8px 16px 54px 0px rgba(52, 52, 52, 0.5);
}

.topFirstViewMovieBlock .topFirstViewMoviePara {
  color: #FFF;
  margin-left: 32px;
  /* font-family: Meiryo; */
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 141.176% */
  letter-spacing: 0.68px;
}

.topFirstViewMovieHexagon {
  position: absolute;
  right: -84px;
  bottom: -72px;
  width: 160px;
  height: 180px;
  background: url(../image/top/top_hexagon_btn_bg.svg) no-repeat center center;
  transition: 1.0s;
}

.topFirstViewMovieHexagon:hover {
  bottom: -82px;
}

.topFirstViewMovieHexagonBtn {
  display: block;
  box-sizing: border-box;
  width: 160px;
  height: 160px;
  padding: 60px 20px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.84px;
  text-decoration: none;
  position: relative;
  z-index: 10;
}

.topMainContentsBlock {
  position: relative;
  width: 100%;
}

/* 3Dモデルスライダー */
.modelSliderContainer {
  position: relative;
  padding-top: 320px;
  height: 400px;
}

.modelSliderBlock {
  position: absolute;
  bottom: 10px;
  /*元は-44px*/
}

.modelLoopSlide {
  display: flex;
  width: 100vw;
  overflow: hidden;
  padding-top: 150px;
  margin-top: -150px;
}

.modelLoopSlide ul {
  position: relative;
  padding: 0;
  width: 100%;
  display: flex;
}

.modelLoopSlide ul {
  width: max-content;
  animation: slide1 40s normal linear infinite;
}

.modelLoopSlide ul li {
  width: 240px;
  height: 240px;
  margin-right: 24px;
  list-style: none;
}

.modelLoopSlide ul li img {
  width: 240px;
}

.modelExampleItem {
  position: relative;
  cursor: default;
}

.modelExampleItemInner {
  position: relative;
  left: 0;
  bottom: 0;
  width: 240px;
  height: 240px;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff2cc;
}

.modelExampleItemCC {
  display: flex;
  align-items: center;
  margin-top: 8px;
  transition: all 0.3s;
}

.modelExampleItemCC img {
  width: 20px !important;
  margin-right: 4px;
}

.modelExampleItemCC span {
  display: inline-block;
  margin-top: 2px;
}

.modelExampleItemInner img {
  transition: all 0.3s;
}

.modelExampleItemInner span {
  font-size: 14px;
  font-weight: 700;
  transition: all 0.3s;
}

.modelExampleItem:hover .modelExampleItemInnerActive {
  width: 360px;
  height: 360px;
  left: -60px;
  bottom: 120px;
}

.modelExampleItem:hover .modelExampleItemInnerActive img {
  width: 300px;
}

.modelExampleItem:hover .modelExampleItemInnerActive .modelExampleItemCC {
  padding-top: 40px;
}

.modelExampleItem:hover .modelExampleItemInnerActive .modelExampleItemCC span {
  font-size: 16px;
}

.modelExampleItem:hover .modelExampleItemInnerActive .modelExampleItemCC img {
  width: 24px !important;
}

.modelExampleDownload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  transition: all 0.3s;
  width: 220px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #ff8f22;
  color: #ffffff;
  text-align: center;
}

.modelExampleDownloadActive {
  display: block;
}

.modelExampleDownload button {
  margin-top: 10px;
  background: transparent;
  border: 2px solid #ff8f22;
  border-radius: 20px;
  transition: all 0.5s ease-out;
  padding: 4px 12px;
}

.modelExampleDownload button:first-child {
  margin-right: 12px;
}

.modelExampleDownload button:hover {
  background-color: #ffffff;
  color: #ff8f22;
}

.modelSliderDescription {
  padding-top: 12px;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
}

.firstLine {
  display: flex;
  align-items: center;
}

.modelSliderDescription img {
  width: 20px;
  margin-right: 4px;
}

@keyframes slide1 {
  to {
    transform: translateX(-50%);
  }
}

/* 3D-FABsについて */
#about3dfabs {
  width: 100%;
  background: #1C6ECD;
}

#about3dfabs .backgroundTitleText {
  padding-top: 80px;
}

#about3dfabsPoints {
  width: 100%;
  background: #0F65C9;
}

.about3dfabsPointsBlock {
  width: 1150px;
  margin: 0 auto;
  padding: 80px 0 120px;
}

.about3dfabsContentsBlock {
  width: 1150px;
  margin: 0 auto;
  padding: 80px 0 120px;
}

.backgroundTitleText {
  text-align: center;
  padding-top: 104px;
}

.backgroundTitleText {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 120px;
  color: #0F65C9;
  font-weight: bold;
  letter-spacing: 0.04em;
  white-space: nowrap;
}

.about3dfabsHeadline {
  margin-bottom: 64px;
  color: #FFF;
  /* font-family: Meiryo; */
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  /* 115% */
  letter-spacing: 1.6px;
  text-align: center;
}

.about3dfabs3PointsList {
  width: 1078px;
  margin: 0 auto;
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.about3dfabs3PointsList li {
  width: 300px;
  position: relative;
}

.about3dfabs3stepsList {
  width: 1078px;
  margin: 0 auto;
  min-height: 340px;
  background: url(../image/top/about_3dfabs_3steps_bg.png) no-repeat top center;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.about3dfabs3stepsList li {
  width: 300px;
  position: relative;
}

.about3dfabs3stepsListH3 {
  margin-top: 44px;
  margin-bottom: 12px;
  /* font-family: Meiryo; */
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.about3dfabs3stepsList>li:nth-child(1):before,
.about3dfabs3stepsList>li:nth-child(2):before,
.about3dfabs3stepsList>li:nth-child(3):before {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  left: -50px;
  top: 0;
}

.about3dfabs3stepsList li:nth-child(1):before {
  background: url(../image/top/about_3dfabs_3steps_calque_step01.svg) no-repeat top left;
}

.about3dfabs3stepsList li:nth-child(2):before {
  background: url(../image/top/about_3dfabs_3steps_calque_step02.svg) no-repeat top left;
}

.about3dfabs3stepsList li:nth-child(3):before {
  background: url(../image/top/about_3dfabs_3steps_calque_step03.svg) no-repeat top left;
}

.about3dfabs3stepsListImg {
  margin: 0 auto 16px;
}

.about3dfabs3stepsListImg img {
  width: 340px;
  margin-left: -28px;
  height: 175px;
  object-fit: contain;
}

.about3dfabs3PointsListPara {
  /* font-family: Meiryo; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.64px;
  /* margin-left: -60px; */
}

.about3dfabs3PointsListPara img {
  width: 280px;
  height: 280px;
  object-fit: contain;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  margin-left: -60px;
}

.about3dfabs3stepsListPara {
  /* font-family: Meiryo; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.64px;
}

.about3dfabs3stepsListPara ul li {
  list-style: disc;
}

#about3dfabs .conversionButton {
  margin: 40px auto 0;
}

/* 3D-FABsのセキュリティポリシー */
#safeInfo {
  width: 100%;
  background: #1C6ECD;
}

.safeInfoInner {
  width: 1318px;
  height: auto;
  background: #fff;
  margin: 0 auto;
  padding: 80px 12px 40px;
  box-shadow: 0 8px 24px 0 rgba(48, 63, 84, 0.60);
  text-align: center;
  box-sizing: border-box;
}

.safeInfoInner h1 {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 4px;
}

.safeInfoInner p {
  margin: 32px 0;
  letter-spacing: 1px;
  line-height: 24px;
  color: #333333;
}

.safeInfoInner p a {
  width: auto;
  color: #1c6ecd;
  font-weight: bold;
}

.safeInfoHrefInner {
  display: flex;
  flex-flow: column;
  color: #333;
}

/* 3つのできる */
#threeFeatures {
  width: 100%;
  background: #1C6ECD;
}

.threeFeaturesInner {
  width: 1318px;
  height: auto;
  background: #fff;
  margin: 0 auto;
  box-shadow: 0 24px 24px 0 rgba(48, 63, 84, 0.60);
}

.about3dfabsContents1Block {
  width: 100%;
  padding: 72px 0 40px;
}

.about3dfabsContents1Logo {
  width: 180px;
  margin: 0 auto 32px;
}

.about3dfabsContents1Block h1 {
  margin-bottom: 72px;
  /* font-family: Meiryo; */
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  /* 115% */
  letter-spacing: 1.6px;
  text-align: center;
}

.about3dfabsContents1Block h2 {
  margin-bottom: 32px;
  /* font-family: Meiryo; */
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 125% */
  letter-spacing: 1.28px;
  text-align: center;
}

.about3dfabsContents1Block p {
  color: #333;
  text-align: center;
  /* font-family: Meiryo; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.64px;
}

.about3dfabsContents2Block {
  position: relative;
  margin: 0 56px 24px;
  padding: 40px 24px;
  background-color: #FEF4F4;
  border-radius: 24px;
  border: 4px solid #FED2C8;
  display: flex;
  gap: 12px;
  box-sizing: border-box;
}

.about3dfabsContents2Block .threeLeftInner {
  width: 62%;
  position: relative;
}

.about3dfabsContents2Block .threeLeftInner img {
  width: 100%;
  object-fit: contain;
}

.about3dfabsContents2Block .threeLeftInner .threeLeftDownload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  width: 220px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #ff8f22;
  color: #ffffff;
  text-align: center;
  display: none;
}

.about3dfabsContents2Block .threeLeftInner .threeLeftDownloadActive {
  display: block;
}

.about3dfabsContents2Block .threeLeftInner .threeLeftDownload button {
  margin-top: 10px;
  background: transparent;
  border: 2px solid #ff8f22;
  border-radius: 20px;
  transition: all 0.5s ease-out;
  padding: 4px 12px;
}

.about3dfabsContents2Block .threeLeftInner .threeLeftDownload button:first-child {
  margin-right: 12px;
}

.about3dfabsContents2Block .threeLeftInner .threeLeftDownload button:hover {
  background-color: #ffffff;
  color: #ff8f22;
}

.about3dfabsContents2Block .threeRightImg {
  width: 37%;
  object-fit: contain;
}

.about3dfabsContents2Copy p {
  color: #FFF;
  /* font-family: Meiryo; */
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 181.818% */
  letter-spacing: 1.76px;
}

.about3dfabsContents2Image {
  position: absolute;
  top: 40px;
  right: -24px;
}

.about3dfabsContents2Image img {
  width: 100%;
}

.about3dfabsContents3Block {
  padding: 80px 0 55px;
  background: url(../image/top/about_3dfabs_contents4_block_bg.png) no-repeat bottom left;
}

.about3dfabsContents3Block h2 {
  margin-bottom: 32px;
  text-align: center;
  color: #333;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1.28px;
}

.about3dfabsContents3Block .about3dfabsContents3Para {
  margin-bottom: 16px;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.64px;
}

.about3dfabsContents3Block .about3dfabsContents3ParaSub {
  margin-bottom: 40px;
  color: #333;
  text-align: center;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

/* 切り替えタブ */
/* 切り替えタブ1個目（造形姿勢違いによる試算価格の例） */
.about3dfabsContents3Feature1Block {
  display: flex;
  margin: 0 56px 16px;
  border-radius: 12px;
  border: 4px solid #1C6ECD;
  box-sizing: border-box;
  background: #F0F7FF;
  justify-content: space-between;
}

.about3dfabsContents3Feature1BlockLeft {
  padding: 64px 0 64px 48px;
}

.about3dfabsContents3Feature1Tab {
  position: relative;
  width: 52%;
  box-sizing: border-box;
  padding: 48px 56px 48px 141px;
  background: #1C6ECD;
  border-radius: 0px 7px 7px 0px;

}

/* 切り替えタブ2個目（複数個・複数種類違いの試算価格の例） */
.about3dfabsContents3Feature2Block {
  display: flex;
  margin: 0 56px 16px;
  border-radius: 12px;
  border: 4px solid #00B862;
  background: #EEFCF5;
  justify-content: space-between;
}

.about3dfabsContents3Feature2BlockLeft {
  padding: 64px 0 64px 48px;
}

.about3dfabsContents3Feature2Tab {
  position: relative;
  width: 52%;
  box-sizing: border-box;
  padding: 48px 56px 48px 141px;
  background: #00B862;
  border-radius: 0px 7px 7px 0px;

}

/* 切り替えタブ共通部分 */
.about3dfabsContents3FeatureBlockH3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  /* 158.333% */
}

.about3dfabsContents3Feature1Block .about3dfabsContents3FeatureBlockH3 {
  color: #1C6ECD;
}

.about3dfabsContents3Feature2Block .about3dfabsContents3FeatureBlockH3 {
  color: #00B862;
}

.about3dfabsContents3Feature1BlockRight .about3dfabsContents3FeatureBlockH3,
.about3dfabsContents3Feature2BlockRight .about3dfabsContents3FeatureBlockH3 {
  color: #fff;
}

.about3dfabsContents3Feature1BlockLeft .about3dfabsContents3FeatureBlockH3,
.about3dfabsContents3Feature2BlockLeft .about3dfabsContents3FeatureBlockH3 {
  margin-bottom: 18px;
}

.about3dfabsContents3Feature1BlockRight .about3dfabsContents3FeatureBlockH3,
.about3dfabsContents3Feature2BlockRight .about3dfabsContents3FeatureBlockH3 {
  margin-bottom: 16px;
}

.about3dfabsContents3Feature2BlockRight .about3dfabsContents3FeatureBlockH3 span {
  font-size: 14px;
}


.about3dfabsContents3FeatureBlockPara {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.about3dfabsContents3Feature1BlockLeft .about3dfabsContents3FeatureBlockImagePrice {
  max-width: 380px;
  margin-bottom: 8px;
}

.about3dfabsContents3Feature1BlockRight .about3dfabsContents3FeatureBlockImagePrice {
  max-width: 420px;
  margin-bottom: 20px;
}

.about3dfabsContents3FeatureBlockImagePrice img {
  width: 100%;
  margin-bottom: 16px;
}

.about3dfabsContents3FeatureBlockDlist {
  display: flex;
  width: 70%;
  margin-left: 30%;
  text-align: right;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.about3dfabsContents3Feature1BlockLeft .about3dfabsContents3FeatureBlockDlist,
.about3dfabsContents3Feature2BlockLeft .about3dfabsContents3FeatureBlockDlist {
  color: #333;
}

.about3dfabsContents3Feature1BlockRight .about3dfabsContents3FeatureBlockDlist,
.about3dfabsContents3Feature2BlockRight .about3dfabsContents3FeatureBlockDlist {
  color: #fff;
}

.about3dfabsContents3FeatureBlockDlist dt,
.about3dfabsContents3FeatureBlockDlist dd {
  width: calc(100% / 2);
  line-height: 26px;
  /* 81.25% */
}

.about3dfabsContents3FeatureBlockDlist dt:nth-child(1) {
  font-weight: 700;
  font-size: 15px;
}

.about3dfabsContents3FeatureBlockDlist dd:nth-child(2) {
  font-weight: 700;
  font-size: 24px;
}

.about3dfabsContents3FeatureBlockDlist dt:nth-child(3) {
  font-size: 14px;
}

.about3dfabsContents3FeatureBlockDlist dd:nth-child(4) {
  font-size: 16px;
}

/* 切り替えタブ タブ部分 */
.about3dfabsContents3Feature1Tab>label,
.about3dfabsContents3Feature2Tab>label {
  position: absolute;
  flex: 1 1;
  order: -1;
  display: flex;
  box-sizing: border-box;
  left: -100px;
  width: 200px;
  padding: 10px 24px;
  text-align: center;
  cursor: pointer;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
  line-height: 34px;
  /* 212.5% */
}

.about3dfabsContents3Feature1Tab>label {
  color: #1C6ECD;
  border: #FFF 1px solid;
  background: #fff url(../image/top/top_icon_circle_arrow_right_blue.svg) no-repeat right 24px center;
}

.about3dfabsContents3Feature2Tab>label {
  color: #00B862;
  border: #FFF 1px solid;
  background: #fff url(../image/top/top_icon_circle_arrow_right_green.svg) no-repeat right 24px center;
}

.about3dfabsContents3Feature1Tab label.active {
  color: #fff;
  background: #1C6ECD url(../image/top/top_icon_circle_arrow_right_white.svg) no-repeat right 24px center;
}

.about3dfabsContents3Feature2Tab label.active {
  color: #fff;
  background: #00B862 url(../image/top/top_icon_circle_arrow_right_white.svg) no-repeat right 24px center;
}

label.about3dfabsContents3Feature1TabBtn1 {
  top: 172px;
}

label.about3dfabsContents3Feature1TabBtn2 {
  top: 250px;
}

label.about3dfabsContents3Feature1TabBtn3 {
  top: 328px;
}

label.about3dfabsContents3Feature2TabBtn1 {
  top: 178px;
}

label.about3dfabsContents3Feature2TabBtn2 {
  top: 256px;
  line-height: 20px;
  /* 125% */
}

.about3dfabsContents3Feature1Tab input,
.about3dfabsContents3Feature2Tab input {
  display: none;
}

.about3dfabsContents3Feature1Tab>div,
.about3dfabsContents3Feature2Tab>div {
  display: none;
  width: 100%;
}

.about3dfabsContents3Feature1Tab>div {
  background: #1C6ECD;
}

.about3dfabsContents3Feature2Tab>div {
  background: #00B862;
}

.about3dfabsContents3Feature1Tab>div.active,
.about3dfabsContents3Feature2Tab>div.active {
  display: block;
}


/* 補足部分 */
.about3dfabsContents3Additional {
  width: 844px;
  margin: 0 auto;
  padding: 96px 0 80px;
  display: flex;
  justify-content: space-between;
  color: #333;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.8px;
}

.about3dfabsContents3AdditionalList {
  margin-bottom: 16px;
}

.about3dfabsContents3AdditionalList li {
  position: relative;
  padding-left: 1em;
  margin-bottom: 8px;
}

.about3dfabsContents3AdditionalList li:before {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  display: block;
  position: absolute;
  left: 0;
  top: 0.6em;
  content: "";
  background: #1C6ECD;
  /*点の色*/
}

.about3dfabsContents3AdditionalParaSub {
  padding-left: 1.5em;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.about3dfabsContents4Block {
  padding: 100px 0 80px;
}

.about3dfabsContents4Block h2 {
  margin-bottom: 48px;
  color: #333;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1.28px;
  text-align: center;
}

.about3dfabsContents4Inner {
  position: relative;
}

.about3dfabsContents4InnerText {
  width: 45%;
  margin-left: 80px;
  color: #333;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.72px;
}

.about3dfabsContents4List {
  margin-bottom: 24px;
}

.about3dfabsContents4List li {
  position: relative;
  margin-bottom: 24px;
  padding-left: 1em;
  margin-bottom: 8px;
}

.about3dfabsContents4List li:before {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  display: block;
  position: absolute;
  left: 0;
  top: 0.6em;
  content: "";
  background: #FF8F22;
  /*点の色*/
}

.about3dfabsContents4Para {
  padding-left: 1.5em;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.about3dfabsContents4Image {
  position: absolute;
  right: -120px;
  top: -32px;
  width: 750px;
}

.about3dfabsContents4Image img {
  width: 100%;
}

.about3dfabsContents5Image {
  position: absolute;
  left: 90px;
  bottom: -450px;
  width: 650px;
}

.about3dfabsContents5Image img {
  width: 100%;
}

/* 操作説明動画 */
.about3dfabsContents5Block {
  height: 925px;
  padding-top: 144px;
  box-sizing: border-box;
  background: url(../image/top/about_3dfabs_Contents6_bg.png) 50%, linear-gradient(135deg, #00418d, #1c6ecd);
  background-blend-mode: color-burn;
  background-size: cover;
}

.about3dfabsContents5Block h2 {
  color: #FFF;
  text-align: center;
  /* font-family: Meiryo; */
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 125% */
  letter-spacing: 1.28px;
  text-align: center;
}

.about3dfabsContents5Block .about3dfabsContents5BlockIframe {
  width: 800px;
  margin: 24px auto 64px;
}

/*お取引の流れ*/
#transactionFlow {
  background: #EFF4F8 url(../image/top/top_bg_flow.jpg);
}

#transactionFlow .backgroundTitleText02 {
  padding-top: 104px;
  text-align: center;
}

.backgroundTitleText02 span {
  color: #e7eef5;
  font-size: calc(1em + 7vw);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: .04em;
  white-space: nowrap;
}

#transactionFlow h2 {
  font-size: 40px;
  color: #333333;
  font-weight: bold;
  letter-spacing: 0.08em;
  position: relative;
  white-space: nowrap;
}

#transactionFlow h2::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -16px;
  /* 下線の上下位置調整 */
  width: 80px;
  /* 下線の幅 */
  height: 3px;
  /* 下線の太さ */
  background-color: #1C6ECD;
  /* 下線の色 */
}

.transactionFlowBrock {
  width: 1200px;
  margin: -48px auto 0px;
  display: flex;
  position: relative;
}

.transactionFlowLeftContents {
  width: 256px;
  padding: 0 80px;
  margin-right: 64px;
}

.transactionFlowRightContents {
  width: 720px;
}

.movieLinkBlock {
  margin-top: 83px;
}

.movieLinkTitle {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.04em;
  color: #1C6ECD;
  padding-left: 29px;
  margin-bottom: 16px;
  background: url(../image/top/top_icon_video.svg) no-repeat center left;
}

.movieLinkBlock .movieLinkButton {
  display: flex;
  align-items: center;
  background: #fff url(../image/top/top_icon_newopen.svg) no-repeat center right 24px;
  width: 209px;
  height: 56px;
  border-radius: 35px;
  text-decoration: none;
  border: 1px solid #F0EEEF;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0.04em;
  padding-left: 24px;
  margin-bottom: 8px;
}

.movieLinkBlock .movieLinkButtonDisabled {
  display: flex;
  align-items: center;
  background: #fff url(../image/top/top_icon_newopen_disabled.svg) no-repeat center right 24px;
  width: 209px;
  height: 56px;
  border-radius: 35px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #F0EEEF;
  letter-spacing: 0.04em;
  padding-left: 24px;
  margin-bottom: 8px;
  border: 1px solid #F0EEEF;
  pointer-events: none;
}

.movieLinkBlock .movieLinkButton:hover {
  display: flex;
  align-items: center;
  background: #E5F0FA url(../image/top/top_icon_newopen_blue.svg) no-repeat center right 24px;
  color: #1C6ECD;
}

.transactionFlowList li {
  position: relative;
}

.transactionFlowList li .flowArrow::before {
  content: '';
  display: block;
  background: url(../image/top/top_icon_flow_arrowdown.svg) no-repeat;
  width: 48.67px;
  height: 24px;
  position: absolute;
  bottom: -24px;
  left: 50%;
  z-index: 1;
  transform: translateY(-50%);
  -webkit-transform: translateX(-50%);
}

.transactionFlowList li:last-child .flowArrow::before {
  content: none;
}

.flowLabelCustomer .transactionFlowListDetail::before {
  content: 'お客さま';
  position: absolute;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  background-color: #1c6ecd;
  width: 71px;
  height: 21px;
  text-align: center;
  line-height: 23px;
  letter-spacing: 0.08em;
}

.flowLabelStaff .transactionFlowListDetail::before {
  content: '3D-FABs担当者';
  position: absolute;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  background-color: #ff8f22;
  width: 113px;
  height: 21px;
  text-align: center;
  line-height: 23px;
  letter-spacing: 0.08em;
}

.transactionFlowListDetail {
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: 0 5px 20px rgb(60 63 65 / 15%);
  margin-bottom: 32px;
  position: relative;
  z-index: 2;
  padding: 20px;
}

.transactionFlowListDetail h4 {
  width: 200px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0.08em;
  display: flex;
  align-items: center;
  padding: 25px 24px 0 32px;
}

.transactionFlowListDetail p {
  width: 60%;
  font-size: 14px;
  color: #333333;
  align-items: center;
  display: flex;
}

.transactionFlowList li:last-child h4 {
  color: #FF8F22;
}

.transactionFlowList li:last-child .transactionFlowListDetail {
  border: 1px solid #FF8F22;
}

.flowListDetailSignupLink p {
  width: 40%;
  font-size: 14px;
  color: #333333;
  align-items: center;
  display: flex;
}

.flowListDetailSignupLink a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 116px;
  text-decoration: none;
  color: #fff;
  background-color: #FF8F22;
  font-size: 14px;
  font-weight: bold;
  border-radius: 34px;
  margin: 32px auto;
  transition: all .5s ease-out;
}

.flowListDetailSignupLink a:hover {
  opacity: 0.7;
}

.transactionFlowList {
  margin-bottom: 160px;
}

.flowImage01 {
  position: absolute;
  left: 0;
}

.flowImage02 {
  position: absolute;
  bottom: -136px;
  left: 152px;
}

/*お客さまの声*/
#userVoice {
  background: #fff;
}

#userVoice h2 {
  font-size: 40px;
  color: #333333;
  font-weight: bold;
  letter-spacing: 0.08em;
  position: relative;
  white-space: nowrap;
  text-align: center;
  margin-top: -48px;
}

#userVoice h2::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  bottom: -16px;
  width: 80px;
  height: 3px;
  background-color: #1C6ECD;
}

#userVoice .backgroundTitleText03 {
  padding-top: 72px;
  text-align: center;
}

#userVoice .backgroundTitleText03 span {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: .04em;
  white-space: nowrap;
  color: #eff4f8;
  font-size: 120px;
}

.userVoiceBlock {
  margin-top: -44px;
  max-width: 1200px;
  margin: 0 auto;
}

.userVoiceContents {
  width: 100%;
  position: relative;
  margin-bottom: 160px;
}

.userVoiceArrowLeft {
  position: absolute;
  left: -44px;
  top: 50%;
}

.userVoiceArrowRight {
  position: absolute;
  right: -44px;
  top: 50%;
}

.userVoiceContents ul {
  display: flex;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.userVoiceContents li {
  position: relative;
  width: 260px;
  padding: 40px 40px 24px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 5px 20px rgb(28, 110, 205, 0.14);
  margin: 82px 28px 0;
}

.userVoiceContents li .userVoicePersonIcon {
  position: absolute;
  bottom: -16px;
  right: -16px;
}

.userVoiceContents li::before {
  content: url(../image/top/top_icon_userVoice_comment.svg);
  position: absolute;
  top: -8px;
  left: 28px;
}

.userVoiceContents h3 {
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  color: #1C6ECD;
  margin-bottom: 16px;
}

.userVoiceCmmentDetail {
  font-size: 14px;
  color: #333333;
  padding-bottom: 24px;
  margin-bottom: 16px;
  border-bottom: 1px solid #F0EEEF;
}

.userVoiceCompanyName {
  font-size: 13px;
  font-weight: bold;
  line-height: 21px;
}

.swiper-slide {
  width: auto;
}

.userVoiceSwiperButton .swiper-button-prev:after,
.userVoiceSwiperButton .swiper-button-next:after {
  --swiper-navigation-size: 40px;
}

.userVoiceSwiperButtonDisabled {
  visibility: hidden;
}

.userVoiceSwiper {
  position: relative;
  /* width: 260px; */
  padding: 40px 40px 24px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 5px 20px rgb(28, 110, 205, 0.14);
  margin: 82px 28px 82px;
}

.userVoiceSwiper .userVoicePersonIcon {
  position: absolute;
  bottom: -10px;
  right: 0px;
}

.userVoiceSwiper h3 {
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  color: #1c6ecd;
  margin-bottom: 16px;
  word-wrap: break-word;
}

.userVoiceSwiper::before {
  content: url(../image/top/top_icon_userVoice_comment.svg);
  position: absolute;
  top: -8px;
  left: 28px;
}

.userVoiceCmmentDetail {
  font-size: 14px;
  color: #333333;
  padding-bottom: 24px;
  margin-bottom: 16px;
  border-bottom: 1px solid #f0eeef;
  word-wrap: break-word;
  white-space: pre-line;
}

.userVoiceCompanyName {
  font-size: 13px;
  font-weight: bold;
  line-height: 21px;
  white-space: pre-line;
}

/*FAQ*/
#faq {
  background: url(../image/top/top_bg_faq.png) center, linear-gradient(135deg, #00418d, #1c6ecd);
  padding-top: 160px;
  padding-bottom: 136px;
  background-size: cover;
  background-blend-mode: color-burn;
}

#faq h2 {
  font-size: 50px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.08em;
  position: relative;
  white-space: nowrap;
  font-family: Arial, Helvetica, sans-serif;
}

#faq h2::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -16px;
  width: 80px;
  height: 3px;
  background-color: #fff;
}

/* FAQ アコーディオン*/
.faqBlock {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.faqLeftContents {
  margin-left: 20px;
  margin-right: 20px;
}

.faqAccordion {
  max-width: 880px;
}

.toggle {
  display: none;
}

.faqLabel {
  /*タイトル*/
  padding: 24px 32px 22px;
  display: block;
  color: #333333;
  background: #fff;
}

.faqLabel::before {
  /*タイトル横の矢印*/
  content: url(../image/top/top_icon_plus_blue.svg);
  width: 21px;
  height: 21px;
  position: absolute;
  top: 26px;
  right: 10px;
}

.faqLabel,
.faqContent {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: all 0.3s;
}

.faqContent {
  /*本文*/
  height: 0;
  margin-bottom: 24px;
  padding: 0px 32px;
  overflow: hidden;
  font-size: 16px;
  letter-spacing: 0.04em;
  line-height: 24px;
  color: #333333;
  background-color: #fff;
}

.toggle:checked+.faqLabel+.faqContent {
  /*開閉時*/
  height: auto;
  padding: 24px 32px 32px;
  transition: all .3s;
  border-top: 1px solid #F0EEEF;
}

.toggle:checked+.faqLabel::before {
  content: url(../image/top/top_icon_minus_blue.svg);
}

.faqQuestion {
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  color: #1C6ECD;
  margin-right: 12px;
  vertical-align: top;
  line-height: 24px;
}

.faqRightContents h3 {
  display: inline-block;
  width: 740px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0.04em;
}

.faqAccordionBlock {
  box-shadow: 0 5px 20px rgb(36, 40, 46, 20%);
}

.faqLink {
  margin-top: 83px;
  display: flex;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.08em;
  padding-right: 20px;
  background: url(../image/top/top_icon_arrow_white.svg) no-repeat center right;
  transition: all .5s ease-out;
}

.faqLink:hover {
  opacity: 0.7;
}

/* お知らせ */
#news .backgroundTitleText03 {
  padding-top: 72px;
  text-align: center;
}

#news .backgroundTitleText03 span {
  color: #eff4f8;
  font-size: 120px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: .04em;
  white-space: nowrap;
}

#news h2 {
  font-size: 40px;
  color: #333333;
  font-weight: bold;
  letter-spacing: 0.08em;
  position: relative;
  white-space: nowrap;
  text-align: center;
  margin-top: -48px;
}

#news h2::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  bottom: -16px;
  width: 80px;
  height: 3px;
  background-color: #1C6ECD;
}

.newsContents {
  margin-top: 83px;
  margin-bottom: 60px;
}

.categoryTabArea {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pressReleaseTab {
  width: 400px;
  height: 56px;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0.04em;
  display: inline-block;
  text-align: center;
  line-height: 56px;
  margin-right: 1px;
  border-top-left-radius: 10px;
  background: #FF8F22 url(../image/top/top_icon_arrowdown_white.svg) no-repeat center right 24px;
}

.informationTab {
  width: 400px;
  height: 56px;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0.04em;
  display: inline-block;
  text-align: center;
  line-height: 56px;
  border-top-right-radius: 10px;
  background: #1C6ECD url(../image/top/top_icon_arrowdown_white.svg) no-repeat center right 24px;
  transition: all .3s ease-out;
}

.pressReleaseTab:hover {
  width: 400px;
  height: 56px;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0.04em;
  display: inline-block;
  text-align: center;
  line-height: 56px;
  margin-right: 1px;
  border-top-left-radius: 10px;
  background: #FF8F22 url(../image/top/top_icon_arrowdown_white.svg) no-repeat top 24px right 24px;
  transition: all .3s ease-out;
}

.informationTab:hover {
  background: #1C6ECD url(../image/top/top_icon_arrowdown_white.svg) no-repeat top 24px right 24px;
}

.pressReleaseTab:hover {
  background: #FF8F22 url(../image/top/top_icon_arrowdown_white.svg) no-repeat top 24px right 24px;
}

/* お知らせアコーディオン */
.newsAccordion {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.toggle {
  display: none;
}

.newsLabel {
  /*タイトル*/
  padding: 32px 0px 22px;
  display: block;
  color: #333333;
  background: #fff;
}

.newsLabel::before {
  /*タイトル横の矢印*/
  content: url(../image/top/top_icon_plus_orange.svg);
  width: 21px;
  height: 21px;
  position: absolute;
  top: 48px;
  right: 0px;
}

.newsLabel,
.newsContent {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: all 0.3s;
}

.newsContent {
  /*本文*/
  height: 0;
  margin-bottom: 8px;
  overflow: hidden;
  font-size: 16px;
  letter-spacing: 0.04em;
  line-height: 24px;
  color: #333333;
  background-color: #fff;
}

.toggle:checked+.newsLabel+.newsContent {
  /*開閉時*/
  height: auto;
  padding: 10px 0px 32px;
  transition: all .3s;
}

.toggle:checked+.newsLabel::before {
  content: url(../image/top/top_icon_minus_orange.svg);
}

.newsAccordionBlock h3 {
  display: inline-block;
  max-width: 1150px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0.04em;
}

.articleDate {
  font-size: 16px;
  color: #333333;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 16px;
}

.newsCategoryLabelInformation {
  font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',
    'ＭＳ Ｐゴシック', 'Arial';
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  background-color: #1c6ecd;
  padding: 2px 6px;
  border-radius: 4px;
  margin: 0 8px;
  vertical-align: text-bottom;
}

.newsCategoryLabel {
  font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',
    'ＭＳ Ｐゴシック', 'Arial';
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  background-color: #ff8f22;
  padding: 2px 6px;
  border-radius: 4px;
  margin: 0 8px;
  vertical-align: text-bottom;
}

.newsAccordionBlock {
  border-top: 1px solid #f0eeef;
  margin: 0px 20px 0px 20px;
}

.newsCategoryLabel {
  font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', 'Arial';
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  background-color: #FF8F22;
  padding: 2px 6px;
  border-radius: 4px;
  margin: 0 8px;
  vertical-align: text-bottom;
}

.newsAccordionBlock {
  border-top: 1px solid #F0EEEF;
}

.newsAccordionBlock:last-child {
  border-bottom: 1px solid #F0EEEF;
}

/* リンクエリア */
.linkArea {
  margin-bottom: 80px;
}

.linkAreaContents {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.linkAreaContents h1 {
  color: #044096;
  font-size: 40px;
  letter-spacing: 0.08em;
  margin-bottom: 80px;
  font-weight: bold;
}

.link3dfabs {
  height: 293px;
  border-bottom: 1px solid #044096;
  background-image: url(../image/top/link_bg_3dfabs.png);
}

.otherLink {
  display: flex;
  height: 180px;
}

.linkOrix3dBusiness,
.linkContact {
  width: 50%;
  display: flex;
  justify-content: center;
  line-height: 180px;
}

.link3dfabs h3 {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding-top: 80px;
  margin-bottom: 29px;
}

.link3dfabs a {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  background-color: #FF8F22;
  border-radius: 34px;
  color: #fff;
  text-decoration: none;
  width: 240px;
  height: 56px;
  line-height: 56px;
  letter-spacing: 0.04em;
  transition: all .5s ease-out;
  border: 2px solid #FF8F22;
  box-sizing: border-box;
}

.link3dfabs a:hover {
  background-color: #fff;
  color: #FF8F22;
}

.otherLink a {
  color: #fff;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.04em;
  width: 599.5px;
  transition: all .3s ease-out;
}

.otherLink a:hover {
  background-color: #1153B3;
  background-blend-mode: multiply;
}

.otherLink a img {
  vertical-align: middle;
  margin-right: 24px;
}

.linkOrix3dBusiness {
  background-image: url(../image/top/link_bg_ri.png);
  border-right: 1px solid #044096;
}

.linkContact {
  background-image: url(../image/top/link_bg_contact.png);
  border-right: 1px solid #044096;
}

.newOpenIcon {
  margin-left: 16px;
}

/* バナー */
.rentecInsightBnr {
  width: 100%;
  text-align: center;
  display: flex;
  margin: 16px 0;
  gap: 16px;
  flex-wrap: wrap;
}

.rentecInsightBnr a {
  display: inline-block;
  transition: all .3s ease-out;
}

.rentecInsightBnr a img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.rentecInsightBnr a:hover {
  opacity: 0.7;
}

.rentecInsightBnr a:first-of-type {
  width: 40%;
}

.rentecInsightBnr a:nth-of-type(2) {
  flex: 1;
}

/* ビデオモーダル */
.modalMovieContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.modalMovieContainerIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsiveHidden {
  display: none;
}

@media screen and (max-width: 1024px) {
  .footerInnerOnTopPage {
    width: 100%;
    min-width: 0px;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0px 0px 10px 0px;
  }

  .backgroundTitleText span,
  .backgroundTitleText03 span {
    font-size: 80px;
  }

  .flowImage01,
  .flowImage02 {
    display: none;
  }

  .transactionFlowLeftContents {
    padding: 0 30px;
  }

  .transactionFlowLeftContents h2 {
    text-align: center;
  }

  .transactionFlowLeftContents h2::after {
    left: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateX(-50%);
  }

  .headerInformationContents {
    padding: 0px;
    height: auto;
  }

  .headerInformationContents .headerInfoSwiperSlide {
    flex-flow: column;
  }

  .headerInformationContents .titleText {
    margin: auto 0;
  }

  .headerInfoArticleTitle {
    margin-top: 5px;
  }

  .otherLink a img {
    width: 50px;
  }

  .otherLink a {
    font-size: 18px;
  }

  .otherLink .newOpenIcon {
    width: 25px;
  }
}

@media screen and (max-width: 768px) {

  /* 暫定スマホ対応 */
  .topFirstViewTextH1 {
    font-size: 70px;
  }

  .safeInfoInner {
    width: auto;
  }

  .threeFeaturesInner {
    width: auto;
  }

  .topFirstViewBlock {
    display: block;
    width: 100%;
  }

  .topFirstViewTextBlock .subtext {
    font-size: 12px;
    line-height: 30px;
  }

  .topFirstViewTextH2 {
    margin-bottom: 10px;
    margin-top: -12px;
  }

  .mobileLoginButtonContainer {
    display: flex;
    width: 100%;
    height: 55px;
    line-height: 55px;
  }

  .mobileLoginButtonContainer button {
    border-style: none;
    width: 50%;
    color: #ffffff;
    font-weight: bold;
  }

  .mobileLoginButtonContainer .mobileRegistButton {
    background-color: #FF8F22;
  }

  .mobileLoginButtonContainer .mobileLoginButton {
    background-color: #1c6ecd;
    background-image: url('../image/common/icon_login.svg');
    background-repeat: no-repeat;
    background-position: 26% center;
  }

  .topFirstViewContents {
    padding-top: 20px;
    padding-bottom: 200px;
    background: url(../image/top/top_bg_fv.jpg) 100% 100% / auto no-repeat;
  }

  .topFirstViewMovieBlock {
    top: 80px;
  }

  .topFirstViewMovieHexagon {
    display: none;
  }

  .modelSliderContainer {
    height: auto;
    padding-top: 250px;
  }

  .modelLoopSlide {
    padding-top: 15vw;
    margin-top: -15vw;
  }

  .modelLoopSlide ul {
    flex-basis: 533vw;
  }

  .modelLoopSlide ul li {
    width: 30vw;
    height: 30vw;
  }

  .modelLoopSlide ul li img {
    width: 30vw;
  }

  .modelExampleItemInner {
    width: 30vw;
    height: 30vw;
  }

  .modelExampleItemCC img {
    width: 2.5vw !important;
  }

  .modelExampleItemCC span {
    display: inline-block;
    font-size: 2vw;
  }

  .modelExampleItem:hover .modelExampleItemInnerActive {
    width: 45vw;
    height: 45vw;
    left: -7vw;
    bottom: 15vw;
  }

  .modelExampleItem:hover .modelExampleItemInnerActive img {
    width: 40vw;
  }

  .modelExampleItem:hover .modelExampleItemInnerActive .modelExampleItemCC {
    padding-top: 0;
  }

  .modelExampleItem:hover .modelExampleItemInnerActive .modelExampleItemCC img {
    width: 4vw !important;
  }

  .modelExampleItem:hover .modelExampleItemInnerActive .modelExampleItemCC span {
    font-size: 3vw;
  }

  .modelExampleDownload {
    width: 35vw;
    transform: translate(-50%, -50%);
    font-size: 2.5vw;
    padding: 1vw 1.5vw;
  }

  .modelSliderDescription {
    font-size: 3vw;
    letter-spacing: 0;
  }

  .modelSliderDescription img {
    width: 3.3vw;
  }

  .about3dfabs3stepsList li:before {
    background-size: 75% 100% !important;
  }

  .about3dfabs3stepsListPara {
    font-size: 15px;
  }

  .about3dfabsContents2Image {
    position: relative;
  }

  .about3dfabsContents4InnerText {
    width: 75%;
  }

  .about3dfabsContents4Image {
    position: relative;
    right: 0;
    top: 0;
  }

  .about3dfabsContents5Block .about3dfabsContents5BlockIframe {
    width: 100%;
  }

  .headerSubMenuLinkFaqNoLogin {
    align-items: end;
  }

  .headerSubMenuLinkSignUpBlock,
  .headerSubMenuLinkUserNameBlock {
    display: none;
  }

  .topMainContentsBlock {
    width: 100%;
  }

  .topFirstViewTextBlock .subheading {
    font-size: 12px;
  }

  .topFirstViewTextBlock .catch {
    font-size: 32px;
    line-height: 45px;
  }

  .topFirstViewTextBlock .subtext {
    font-size: 10px;
  }

  .subNaviBlock .subNaviContents {
    width: 100%;
    height: auto;
    flex-flow: column;
    margin: auto;
  }

  .subNaviBlock .subNaviContents li {
    width: 100%;
  }

  .subNaviBlock .subNaviContents li:first-child {
    border-top-left-radius: 0px;
  }

  .subNaviBlock .subNaviContents li:last-child {
    border-top-right-radius: 0px;
  }

  .backgroundTitleText,
  .backgroundTitleText02,
  .backgroundTitleText03 {
    padding-top: 50px;
  }

  .backgroundTitleText span,
  .backgroundTitleText03 span {
    font-size: 35px;
  }

  .backgroundTitleText02 span {
    font-size: 30px;
  }

  .about3dfabsContentsBlock {
    width: 80%;
    flex-flow: column;
    margin: -10px auto 0;
  }

  .about3dfabsLeftContents {
    width: 100%;
    margin-bottom: 20px;
  }

  .about3dfabsRightContents {
    width: 100%;
  }

  .about3dfabsHeadline {
    font-size: 20px;
  }

  .about3dfabsRightContents li h3 {
    font-size: 20px;
  }

  .about3dfabsContents3FeatureBlockDlist dt:nth-child(1) {
    font-size: 14px;
  }

  .about3dfabsContents3FeatureBlockDlist dd:nth-child(2) {
    font-size: 20px;
  }

  .about3dfabsContents3FeatureBlockDlist dt:nth-child(3) {
    font-size: 13px;
  }

  .productFeaturesBlock .productFeaturesContents {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .transactionFlowBrock {
    width: 80%;
    flex-flow: column;
    margin: -10px auto 0px;
  }

  .transactionFlowLeftContents {
    width: 100%;
    padding: 0px;
    margin-right: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .movieLinkBlock {
    margin-top: 30px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .transactionFlowRightContents {
    width: 100%;
  }

  .transactionFlowListDetail {
    flex-flow: column;
    height: auto;
  }

  .transactionFlowListDetail h4 {
    width: 200px;
    font-size: 16px;
    padding: 25px 24px 0 24px;
  }

  .transactionFlowListDetail p,
  .flowListDetailSignupLink p {
    margin: 5px 24px 5px 24px;
    width: auto;
  }

  #userVoice .backgroundTitleText03 span {
    font-size: 35px;
  }

  #userVoice h2 {
    margin-top: -20px;
  }

  .userVoiceBlock {
    width: 100%;
  }

  .faqBlock {
    flex-flow: column;
    width: auto;
    margin-bottom: 20px;
  }

  .faqLink {
    margin-top: 30px;
    margin-bottom: 10px;
    background-position: 130px;
  }

  .faqAccordion {
    width: 100%;
  }

  .faqRightContents h3 {
    width: auto;
  }

  .link3dfabs {
    display: none;
  }

  .linkAreaContents h1 {
    font-size: 28px;
    margin-top: 80px;
  }

  .otherLink {
    flex-flow: column;
    height: auto;
  }

  .linkOrix3dBusiness,
  .linkContact {
    width: 100%;
    line-height: 50px;
  }

  .otherLink a img {
    width: 30px;
  }

  .otherLink a {
    font-size: 16px;
  }

  .footerMenuListOnTopPage {
    flex-flow: column;
    align-items: center;
  }

  .flowListDetailSignupLink a {
    display: none;
  }

  .otherLinkBlock {
    padding: 10px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  #news .backgroundTitleText03 span {
    font-size: 35px;
  }

  .newOpenIcon {
    display: none;
  }

  .newsContents {
    margin-top: 83px;
    margin-bottom: 30px;
  }

  .pressReleaseTab,
  .informationTab {
    font-size: 12px;
    background-position: center right 20px;
  }

  .rentecInsightBnr {
    margin-bottom: 0;
  }

  .rentecInsightBnr a {
    width: 100% !important;
  }

  #faq {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .modelSitePolicysOnTopPage {
    margin: 16px 10px 10px;
  }

  .responsiveHidden {
    display: block;
  }
}


/* スマホ対応 */
@media screen and (max-width: 1024px) {
  .footerInnerOnTopPage {
    width: 100%;
    min-width: 0px;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0px 0px 10px 0px;
  }

  .backgroundTitleText span,
  .backgroundTitleText03 span {
    font-size: 80px;
  }

  .flowImage01,
  .flowImage02 {
    display: none;
  }

  .transactionFlowLeftContents {
    padding: 0 30px;
  }

  .transactionFlowLeftContents h2 {
    text-align: center;
  }

  .transactionFlowLeftContents h2::after {
    left: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateX(-50%);
  }

  .headerInformationContents {
    padding: 0px;
    height: auto;
  }

  .headerInformationContents .headerInfoSwiperSlide {
    flex-flow: column;
  }

  .headerInfoArticleTitle {
    margin-top: 5px;
  }

  .otherLink a img {
    width: 50px;
  }

  .otherLink a {
    font-size: 18px;
  }

  .otherLink .newOpenIcon {
    width: 25px;
  }
}

@media screen and (max-width: 768px) {
  .headerSubMenuLinkFaqNoLogin {
    align-items: end;
  }

  .headerSubMenuLinkSignUpBlock,
  .headerSubMenuLinkUserNameBlock {
    display: none;
  }

  .topMainContentsBlock {
    width: 100%;
  }

  .topFirstViewTextBlock {
    padding: 0 20px;
    position: relative;
  }

  .topFirstViewTextBlock .subheading {
    font-size: 12px;
  }

  .topFirstViewTextBlock .catch {
    font-size: 32px;
    line-height: 45px;
  }

  .topFirstViewTextBlock .subtext {
    font-size: 10px;
  }

  .topFirstViewButtonArea {
    position: absolute;
    right: 16px;
    bottom: auto;
    margin: 0;
  }

  .topFirstViewButtonAreaHide {
    display: none;
  }

  .conversionButton a.btnOrangeSignup {
    width: auto;
    margin: 0;
    padding: 8px 56px;
    font-size: 16px;
    line-height: 16px;
  }

  .subNaviBlock .subNaviContents {
    width: 100%;
    height: auto;
    flex-flow: column;
    margin: auto;
  }

  .subNaviBlock .subNaviContents li {
    width: 100%;
    padding-right: 0;
    margin-right: 0;
  }

  .subNaviBlock .subNaviContents li:first-child {
    border-top-left-radius: 0px;
  }

  .subNaviBlock .subNaviContents li:last-child {
    border-top-right-radius: 0px;
  }

  .backgroundTitleText,
  .backgroundTitleText02,
  .backgroundTitleText03 {
    padding-top: 50px;
  }

  .backgroundTitleText span,
  .backgroundTitleText03 span {
    font-size: 35px;
  }

  .backgroundTitleText02 span {
    font-size: 30px;
  }

  .about3dfabsPointsBlock {
    width: auto;
    flex-flow: column;
    margin: -10px auto 0;
  }

  .about3dfabsContentsBlock {
    width: auto;
    flex-flow: column;
    margin: -10px auto 0;
  }

  .about3dfabsLeftContents {
    width: 100%;
    margin-bottom: 20px;
  }

  .about3dfabsRightContents {
    width: 100%;
  }

  .about3dfabsHeadline {
    font-size: 20px;
  }

  .about3dfabsRightContents li h3 {
    font-size: 20px;
  }

  .productFeaturesBlock .productFeaturesContents {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .transactionFlowBrock {
    width: 80%;
    flex-flow: column;
    margin: -10px auto 0px;
  }

  .transactionFlowLeftContents {
    width: 100%;
    padding: 0px;
    margin-right: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .movieLinkBlock {
    margin-top: 30px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .transactionFlowRightContents {
    width: 100%;
  }

  .transactionFlowListDetail {
    flex-flow: column;
    height: auto;
  }

  .transactionFlowListDetail h4 {
    width: 200px;
    font-size: 16px;
    padding: 25px 24px 0 24px;
  }

  .transactionFlowListDetail p,
  .flowListDetailSignupLink p {
    margin: 5px 24px 5px 24px;
    width: auto;
  }

  #userVoice h2 {
    margin-top: -20px;
  }

  .userVoiceBlock {
    width: 100%;
  }

  .faqBlock {
    flex-flow: column;
    width: auto;
    margin-bottom: 20px;
  }

  .faqLink {
    margin-top: 30px;
    margin-bottom: 10px;
    background-position: 130px;
  }

  .faqAccordion {
    width: 100%;
  }

  .faqRightContents h3 {
    width: auto;
  }

  .link3dfabs {
    display: none;
  }

  .otherLink {
    flex-flow: column;
    height: auto;
  }

  .linkOrix3dBusiness,
  .linkContact {
    width: 100%;
    line-height: 50px;
  }

  .otherLink a img {
    width: 30px;
  }

  .otherLink a {
    font-size: 16px;
  }

  .footerMenuListOnTopPage {
    flex-flow: column;
    align-items: center;
  }

  .flowListDetailSignupLink a {
    display: none;
  }

  .otherLinkBlock {
    padding: 10px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .newOpenIcon {
    display: none;
  }

  .newsContents {
    margin-top: 83px;
    margin-bottom: 30px;
  }

  .pressReleaseTab,
  .informationTab {
    font-size: 12px;
    background-position: center right 20px;
  }

  .rentecInsightBnr {
    margin-bottom: 0px;
  }

  #faq {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .modelSitePolicysOnTopPage {
    margin: 16px 10px 10px;
  }

  .responsiveHidden {
    display: block;
  }

  /* 切り替えタブ1個目（造形姿勢違いによる試算価格の例） */
  .about3dfabsContents3Feature1Block {
    display: block;
    margin: 0 5px 16px;
    border-radius: 12px;
    border: 4px solid #1C6ECD;
    box-sizing: border-box;
    background: #F0F7FF;
    justify-content: space-between;
  }

  .about3dfabsContents3Feature1BlockLeft {
    padding: 30px 48px 115px 48px;
  }

  .about3dfabsContents3Feature1Tab {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 115px 56px 30px 56px;
    background: #1C6ECD;
    border-radius: 0px 7px 7px 0px;

  }

  /* 切り替えタブ2個目（複数個・複数種類違いの試算価格の例） */
  .about3dfabsContents3Feature2Block {
    display: block;
    margin: 0 5px 16px;
    border-radius: 12px;
    border: 4px solid #00B862;
    background: #EEFCF5;
    justify-content: space-between;
  }

  .about3dfabsContents3Feature2BlockLeft {
    padding: 30px 48px 80px 48px;
  }

  .about3dfabsContents3Feature2Tab {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 80px 56px 30px 56px;
    background: #00B862;
    border-radius: 0px 7px 7px 0px;

  }

  /* 切り替えタブ タブ部分 */
  .about3dfabsContents3Feature1Tab>label,
  .about3dfabsContents3Feature2Tab>label {
    position: absolute;
    flex: 1 1;
    order: -1;
    display: flex;
    box-sizing: border-box;
    left: 100px;
    width: 200px;
    padding: 10px 24px;
    text-align: center;
    cursor: pointer;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 700;
    line-height: 34px;
    /* 212.5% */
  }

  label.about3dfabsContents3Feature1TabBtn1 {
    top: -95px;
  }

  label.about3dfabsContents3Feature1TabBtn2 {
    top: -30px;
  }

  label.about3dfabsContents3Feature1TabBtn3 {
    top: 35px;
  }

  label.about3dfabsContents3Feature2TabBtn1 {
    top: -55px;
  }

  label.about3dfabsContents3Feature2TabBtn2 {
    top: 5px;
    line-height: 20px;
    /* 125% */
  }

  .about3dfabsContents3FeatureBlockDlist {
    display: flex;
    width: 100%;
    margin-left: 0%;
    text-align: right;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  /* 画像のスマホ版 */
  .about3dfabsContents4Image {
    width: 400px;
    position: absolute;
    right: -25px;
    top: 400px;
  }

  .about3dfabs3PointsList {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .about3dfabs3PointsListPara img {
    margin-left: 0;
  }

  .about3dfabs3PointsListPara ul {
    text-align: center;
  }

  .about3dfabs3stepsList {
    width: auto;
    margin: auto 10px;
    /* min-height: 1200px; */
    background: url(../image/top/about_3dfabs_3steps_vertical_bg.png) no-repeat top center;
    display: block;
    justify-content: space-between;
    color: #fff;
  }

  .about3dfabsContents2Image {
    position: relative;
    top: 0px;
    right: 50px;
    height: 200px;
  }

  .about3dfabsContents2Image img {
    width: calc(100% + 100px);
  }

  /* 動画 */
  .pc-video {
    display: none;
    /* PC版の動画を非表示に */
  }

  .mobile-video {
    width: auto;
    height: auto;
    display: block;
    /* スマートフォン版の動画を表示 */
    margin: auto;
  }

  /*サブ ナビゲーション*/
  .subNaviBlock {
    position: relative;
    top: 0px;
    right: 0px;
    padding: 0 10px;
  }

  /* 文字関連 */
  .topFirstViewMovieBlock .topFirstViewMoviePara {
    color: #FFF;
    margin-left: 15px;
    /* font-family: Meiryo; */
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 141.176% */
    letter-spacing: 0.68px;
    white-space: pre-wrap;
  }

  .about3dfabsContents1Block h2 {
    margin-bottom: 32px;
    /* font-family: Meiryo; */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 125% */
    letter-spacing: 1.28px;
    white-space: pre-wrap;
    text-align: center;
  }

  .about3dfabsContents1Block p {
    color: #333;
    text-align: center;
    /* font-family: Meiryo; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.64px;
    white-space: pre-wrap;
  }

  .about3dfabsContents2Block {
    position: relative;
    margin: 0 8px 24px;
    padding: 40px 24px;
    flex-flow: column;
  }

  .about3dfabsContents2Block .threeLeftInner,
  .about3dfabsContents2Block .threeRightImg {
    width: 100%;
  }

  .about3dfabsContents3Additional {
    width: calc(100% - 10px);
    margin: 0 10px;
    padding: 90px 0 80px;
    display: block;
    justify-content: space-between;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: 0.8px;
    white-space: pre-wrap;
  }

  .about3dfabsContents4Block {
    padding: 100px 0 24px;
  }

  .about3dfabsContents4InnerText {
    width: auto;
    margin: 0 25px;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.72px;
  }

  .about3dfabsContents4Image {
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
  }

  .about3dfabsContents5Image {
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .about3dfabsContents3Block {
    padding: 120px 0 10px;
    background: url(../image/top/about_3dfabs_contents4_block_bg.png) no-repeat bottom left;
  }

  .about3dfabsContents5Block {
    height: 400px;
    padding-top: 100px;
    box-sizing: border-box;
    background: url(../image/top/about_3dfabs_Contents6_bg.png) 50%, linear-gradient(135deg, #00418d, #1c6ecd);
    background-blend-mode: color-burn;
    background-size: cover;
  }

  .about3dfabs3stepsList>li {
    width: 300px;
    top: 40px;
    height: 400px;
    position: relative;
    margin: auto;
  }

  .about3dfabs3stepsListH3 {
    /* margin-top: 110px; */
    margin: auto;
    /* margin-bottom: 20px; */
    /* font-family: Meiryo; */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
  }

  .about3dfabs3stepsListImg img {
    width: 300px;
    margin: 20px 0 0;
  }

  .topFirstViewTextBlock {
    padding: 10px;
  }

  .about3dfabs3stepsList>li:nth-child(1):before,
  .about3dfabs3stepsList>li:nth-child(2):before,
  .about3dfabs3stepsList>li:nth-child(3):before {
    content: "";
    display: block;
    width: 120px;
    height: 100px;
    position: absolute;
    left: -35px;
    top: -40px;
  }
}