@charset "UTF-8";
/* -------------------------------------
 * clientLogin.css
 * 顧客画面_ログイン画面周り用
------------------------------------- */
.pageTitleDetailTextInput {
  margin: 16px 0 16px 0;
  color: #333;
  font-size: 14px;
}
.loginInputBlock {
  display: flex;
  justify-items: center;
  align-items: center;
}
.loginInputBlock .loginIdLabel,
.loginInputBlock .loginPassLabel {
  display: block;
  text-align: left;
  margin-bottom: 8px;
  font-weight: bold;
}
.btnOrangeCommit {
  padding: 8px 24px;
  min-width: 160px;
}

.pageTitleDetailText {
  margin-bottom: 24px;
}
/*ボタン*/
.clientpasswordResetButtonArea .btnOrangeCommit {
  display: inline-block;
  padding: 8px 24px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border-radius: 24px;
  border: none;
  background-color: #FF8F22;
  border: 2px solid #FF8F22;
  box-sizing: border-box;
  transition: all .5s ease-out;
}
.clientpasswordResetButtonArea .btnOrangeCommit:hover {
  color: #FF8F22;
background-color: #ffffff;
}
.btnCancel {
    display: inline-block;
    height: 40px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: #8B8B8B;
    border-radius: 24px;
    background-color: #fff;
    border: 2px solid #8B8B8B;
    box-sizing: border-box;
    transition: all .5s ease-out;
    min-width: 160px;
    margin-right: 10px;
    margin-top: 0;
}
.btnCancel:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  background-color: #8B8B8B;
  min-width: 160px;
}
.clientLoginButtonArea .btnBlueCommit {
  display: inline-block;
  padding: 8px 40px 8px 24px;
  min-width: 160px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border-radius: 24px;
  border: none;
  background: #1C6ECD url(../image/common/icon_login.svg) no-repeat center right 25%;
  border: 2px solid #1C6ECD;
  box-sizing: border-box;
  transition: all .5s ease-out;
}
.clientLoginButtonArea .btnBlueCommit:hover {
  color: #1C6ECD;
  background: #fff url(../image/common/icon_login_blue.svg) no-repeat center right 25%;
}
/*
パスワードリセットページ
ワンタイムパスワード
新規パスワード入力
パスワードリセット完了
ログアウト
*/
.passwodResetInputBlock,
.oneTimePasswodInputBlock{
    margin-bottom: 32px;
}
.passwodResetInputBlock input,
.oneTimePasswodInputBlock input{
    margin-right: 14px;
}
.passwodResetInputTelMail,
.oneTimePasswodInput,
.newPasswodInput{
    width: 608px;
}
.clientpasswordResetButtonArea{
  padding-top: 8px;
}
.newPasswodInput{
  margin-bottom: 8px;
}
.passwordResetCompleted,
.logout{
  margin-bottom: 40px;
}
/*ログイン*/
.clientLogin {
  text-align: center;
  width: 425px;
  margin: 0 auto;
  padding-top: 80px;
}
.clientLogin .pageTitleInner {
  display: inline-block;
}
.clientLogin .pageTitle{
  font-size: 24px;
  letter-spacing: 0.04em;
}
.loginIdInput{
  width: 406px;
  margin-bottom: 24px;
  background-color: #F8FBFF;
}
.loginPassInput{
  width: 406px;
  background:#F8FBFF url(../image/common/icon_pass_hidden.svg) no-repeat center right 12px;
}
.clientLoginButtonArea{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #F0EEEF;
  padding-bottom: 48px;
  margin-bottom: 48px;
}
.clientLoginButtonArea .forgotPassword{
  font-size: 12px;
  color: #333;
}
.clientLoginButtonArea .forgotPassword a{
  color: #1C6ECD;
}
.clientSignUpButtonArea{
  margin-top: 16px;
}

@media screen and (max-width: 768px) {
  .clientLogin {
    width: 100%;
  }

  .pageTitleDetailTextInput {
    margin: 24px;
  }
}
