@charset "UTF-8";
.PDF-Page {
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.PDF-Page canvas {
  margin-left: auto;
  margin-right: auto;
}
