@charset "UTF-8";
/* -------------------------------------
 * trialBalanceSpecEdit.css
 * 試算仕様ページ用
------------------------------------- */
.trialBalanceSpecEditBlock {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.trialBalanceSpecEditBlock .pageTitle {
  margin-bottom: 40px;
}
/* -----------------------------
 * フォーム
 ------------------------------*/
.trialBalanceSpecEditFormBlock {
  width: 48%;
}
.trialBalanceSpecEditFormAccordionContent {
  margin-bottom: 16px;
}
.trialBalanceSpecEditFormToggle {
  display: none;
}
.trialBalanceSpecEditFormToggleLabel {
  display: block;
  width: 100%;
  height: 40px;
  padding-left: 1em;
  font-size: 16px;
  font-weight: bold;
  color: #1c6ecd;
  line-height: 40px;
  background-color: #e6eef8;
  border-bottom: 2px solid #1c6ecd;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
}
.trialBalanceSpecEditFormToggleLabel:after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../image/common/icon_toggle_up_blue.svg) no-repeat right center;
  position: absolute;
  top: calc(50% - 8px);
  right: 12px;
}
.trialBalanceSpecEditFormToggle:checked + .trialBalanceSpecEditFormToggleLabel:after {
  background: url(../image/common/icon_toggle_down_blue.svg) no-repeat right center;
}
.trialBalanceSpecEditFormToggleLabel,
.trialBalanceSpecEditFormToggleContentBlock {
  position: relative;
  z-index: 0;
  /*-webkit-backface-visibility: hidden;*/
  /*backface-visibility: hidden;*/
  /*transform: translateZ(0);*/
  /*transition: all 0.3s;*/
}
.trialBalanceSpecEditFormToggleContentBlock {
  height: auto;
  margin-bottom: 10px;
  padding: 12px 0;
  overflow: hidden;
}
.trialBalanceSpecEditFormToggle:checked
  + .trialBalanceSpecEditFormToggleLabel
  + .trialBalanceSpecEditFormToggleContentBlock {
  height: 0;
  padding: 0;
  transition: all 0.3s;
}
.trialBalanceSpecEditFormToggleContent {
  margin-bottom: 12px;
}
.trialBalanceSpecEditFormInputBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.trialBalanceSpecEditFormToggleContent label {
  display: inline-block;
  width: 40%;
  padding: 8px 0;
  color: #333333;
  font-size: 14px;
}
.trialBalanceSpecEditFormToggleContent label p {
  font-size: 12px;
  padding-top: 4px;
}
.trialBalanceSpecEditFormToggleContent label p a {
  color: #0856ba;
  text-decoration: underline;
}
.trialBalanceSpecEditFormToggleContent input {
  display: inline-block;
  width: 60%;
  padding: 8px;
  font-size: 14px;
  color: #333333;
  border: 1px solid #dfe1e3;
}
.trialBalanceSpecEditFormToggleContent textarea {
  display: inline-block;
  width: 60%;
  padding: 8px;
  font-size: 14px;
  color: #333333;
  border: 1px solid #dfe1e3;
  box-sizing: border-box;
}
.trialBalanceSpecEditFormToggleContent select {
  width: 60%;
  max-height: 40px;
  font-size: 14px;
}
.trialBalanceSpecEditFormToggleContent input:disabled,
.trialBalanceSpecEditFormToggleContent textarea:disabled {
  background: #f0eeef;
}
.trialBalanceSpecEditFormCalendarBlock {
  position: relative;
}
.trialBalanceSpecEditFormCalendarBlock:hover .trialBalanceSpecEditFormCalendarAlert {
  opacity: 1;
}
.trialBalanceSpecEditFormCalendarBlock .trialBalanceSpecEditFormCalendarAlert {
  opacity: 0;
  transition: all 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FF8F22;
  border: 2px solid #FF8F22;
  background-color: #ffffff;
  padding: 16px 32px;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  z-index: 102;
}
/* 3Dデータアップロード */
.trialBalanceSpecEditFormFileUploadBlock {
  width: 100%;
}
.trialBalanceSpecEditFormFileUploadArea {
  padding: 24px 0;
  margin-bottom: 16px;
  text-align: center;
  border: 1px dotted #1c6ecd;
  border-radius: 3px;
}
.trialBalanceSpecEditFormFileUploadAreaImage {
  margin-bottom: 8px;
}
.trialBalanceSpecEditFormFileUploadAreaPara1 {
  margin-bottom: 8px;
  font-size: 14px;
  color: #8b8b8b;
  text-align: center;
}
.trialBalanceSpecEditFormFileUploadAreaPara2 {
  font-size: 12px;
  color: #8b8b8b;
}
.trialBalanceSpecEditFormFileUploadAreaLink {
  color: #1c6ecd;
}
.trialBalanceSpecEditFormFileUploadedName {
}
.trialBalanceSpecEditFormFileUploadedLink {
  padding-right: 24px;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  text-decoration: none;
  background: url(../image/common/icon_close_gray.svg) no-repeat right center;
}
/* 造形可否判定結果 */
.trialBalanceSpecEditFormCheckTag {
  display: inline;
  margin-left: 4px;
  padding: 4px 12px;
  font-weight: bold;
  font-size: 12px;
  font-family: 'Arial';
  color: #ffffff;
  border-radius: 3px;
}
.trialBalanceSpecEditFormCheckTagOk {
  background: #00b862;
}
.trialBalanceSpecEditFormCheckTagPartiallyOk {
  background: #ff8f22;
}
.trialBalanceSpecEditFormCheckTagNg {
  background: #d70a30;
}
.trialBalanceSpecEditFormCheckDetail {
  margin-top: -12px;
  border: 1px solid #f0eeef;
  padding: 16px;
}
.trialBalanceSpecEditFormCheckPara {
  margin-bottom: 8px;
  padding-left: 2em;
  font-size: 12px;
}
.trialBalanceSpecEditFormCheckParaOk {
  background: url(../image/common/icon_check_ok_green.svg) no-repeat 0% 8%;
}
.trialBalanceSpecEditFormCheckParaPartiallyOk {
  background: url(../image/common/icon_check_ok_orange.svg) no-repeat 0% 8%;
}
.trialBalanceSpecEditFormCheckParaNg {
  background: url(../image/common/icon_check_ng_red.svg) no-repeat 0% 8%;
}
.trialBalanceSpecEditFormCheckLinkOk,
.trialBalanceSpecEditFormCheckLinkPartiallyOk,
.trialBalanceSpecEditFormCheckLinkNg {
  font-weight: bold;
}
.trialBalanceSpecEditFormCheckLinkOk {
  color: #00b862;
}
.trialBalanceSpecEditFormCheckLinkPartiallyOk {
  color: #ff8f22;
}
.trialBalanceSpecEditFormCheckLinkNg {
  color: #d70a30;
}
/* 試算基本情報 */
.trialBalanceSpecEditFormCalculationList {
  list-style: none;
  padding-left: 12px;
}
.trialBalanceSpecEditFormCalculationListItem {
  display: flex;
  padding: 0 12px 0 28px;
  border-bottom: 1px solid #f0eeef;
  line-height: 54px;
}
.trialBalanceSpecEditFormCalculationListItem:nth-child(1) {
  background: url(../image/common/illust_triangle.svg) no-repeat left center;
}
.trialBalanceSpecEditFormCalculationListItem:nth-child(2) {
  background: url(../image/common/illust_rectangle.svg) no-repeat left center;
}
.trialBalanceSpecEditFormCalculationListItem:nth-child(3) {
  background: url(../image/common/illust_cube.svg) no-repeat left center;
}
.trialBalanceSpecEditFormCalculationListItem:nth-child(4) {
  background: url(../image/common/illust_clock.svg) no-repeat left center;
  border-bottom: none;
}
.trialBalanceSpecEditFormCalculationTitle {
  width: 20%;
  font-size: 14px;
  font-weight: bold;
}
.trialBalanceSpecEditFormCalculationDetail {
  width: 80%;
  font-size: 12px;
  height: 54px;
}
.trialBalanceSpecEditFormCalculationDetail span {
  font-size: 10px;
  vertical-align: super;
}
/* オプション */
.trialBalanceSpecEditFormOptionSettingBlock {
}
.trialBalanceSpecEditFormOptionSettingBlock textarea {
  height: 6em;
}
/* 追加要望 */
.trialBalanceSpecEditFormOptionSettingAddRequest {
  display: flex;
  justify-content: space-between;
  margin: 24px 0 12px;
  padding: 12px;
  border-bottom: 1px solid #f0eeef;
}
.trialBalanceSpecEditFormOptionSettingAddRequestPara {
  width: 300px;
  font-size: 12px;
  line-height: 1.4em;
}
/* 列追加UI */
.trialBalanceSpecEditFormInputAddRowBlock {
  width: 60%;
}
.trialBalanceSpecEditFormInputAddRowInner {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.trialBalanceSpecEditFormInputAddRowInner select {
  width: 60%;
}
.trialBalanceSpecEditFormInputAddRowAmountBlock {
  width: 30%;
}
.trialBalanceSpecEditFormInputAddRowAmountBlock select {
  margin-left: 8px;
  height: 40px;
  font-size: 14px;
}
.trialBalanceSpecEditFormInputAddRowAmountBlock select:disabled {
  background: #f0eeef;
}
.trialBalanceSpecEditFormInputAddRow {
  width: 100%;
}
.trialBalanceSpecEditFormInputAddRowLink {
  display: block;
  width: 61.7%;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  color: #1c6ecd;
  text-align: center;
  background-color: #e6eef8;
  border-radius: 4px;
  float: right;
}
.trialBalanceSpecEditFormInputAddRowLinkBlink {
  display: inline-block;
  vertical-align: top;
  background: url(../image/common/icon_circle_plus_blue.svg) no-repeat 38% 50%;
  animation-name: PlusBlink;
  animation-timing-function: step-end;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}
@keyframes PlusBlink {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.trialBalanceSpecEditFormInputAddRowText {
  display: block;
  width: 61.7%;
  height: 27.5px;
  line-height: 24px;
  font-size: 11px;
  font-weight: bold;
  text-decoration: none;
  color: #ff8f22;
  text-align: start;
  border-radius: 4px;
  float: right;
}
.trialBalanceSpecEditFormInputAttention {
  font-size: 12px;
  color: #d70a30;
  width: 61.7%;
  float: right;
  margin-top: -8px;
  margin-bottom: 8px;
}
.trialBalanceSpecEditFormInputRowDeleteLink {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 12px 0 0 8px;
  background: url(../image/common/icon_circle_cross_gray.svg) no-repeat top center;
}
/* マウスオーバーサブ情報 */
.trialBalanceSpecEditFormSubInfoBlock {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  background: rgba(0, 86, 186, 0.9);
}
.trialBalanceSpecEditFormSubInfoRow {
  display: flex;
  border-bottom: 1px solid #508cd0;
  font-size: 12px;
  color: #ffffff;
}
.trialBalanceSpecEditFormSubInfoTitle {
  padding: 8px;
  border-right: 1px solid #508cd0;
  font-weight: bold;
  flex: 1;
}
.trialBalanceSpecEditFormSubInfoDetail {
  flex: 2;
  padding: 8px;
}
/* トグルスイッチ */
.toggleSwitchLayer {
  width: 100%;
  background-color: #ff8f22;
  transition: 0.3s ease all;
  z-index: 1;
}
.toggleSwitchInner {
  position: relative;
  top: 50%;
  width: 80px;
  height: 36px;
  border-radius: 80px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}
.toggleSwitchCheckbox {
  position: relative;
  width: 100% !important;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.toggleSwitchKnobs {
  z-index: 2;
}
.toggleSwitchKnobs,
.toggleSwitchLayer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.toggleSwitchKnobs:before,
.toggleSwitchKnobs:after,
.toggleSwitchKnobs span {
  position: absolute;
  top: 4px;
  width: 20px;
  height: 10px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  border-radius: 40px;
  transition: 0.3s ease all;
}
.toggleSwitchKnobs:before,
.toggleSwitchKnobs:after {
  color: #ffffff;
  z-index: 1;
  font-family: 'Arial';
}
.toggleSwitchKnobs:before {
  content: 'ON';
  left: 8px;
}
.toggleSwitchKnobs:after {
  content: 'OFF';
  right: 8px;
}
.toggleSwitchKnobs span {
  width: 25px;
  left: 42px;
  background-color: #ffffff;
  z-index: 2;
  box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
.toggleSwitchCheckbox:checked + .toggleSwitchKnobs span {
  left: 4px;
}
.toggleSwitchCheckbox:checked ~ .toggleSwitchLayer {
  background-color: #8b8b8b;
}
/* 追加ファイルアップロード */
.trialBalanceSpecEditFormOptionSettingFileUploadBlock {
  width: 60%;
  padding: 48px 16px 16px;
  border-radius: 3px;
  border: 1px dotted #1c6ecd;
  box-sizing: border-box;
}
.trialBalanceSpecEditFormOptionSettingFileUploadBlockDisalbed {
  background: url(../image/common/illust_cloud_gray.svg) no-repeat 50% 20%;
  background-color: #f0eeef;
}
.trialBalanceSpecEditFormOptionSettingFileUploadBlockActive {
  background: url(../image/common/illust_cloud_blue.svg) no-repeat 50% 20%;
  background-color: #ffffff;
}
.trialBalanceSpecEditFormOptionSettingFileUploadPara {
  color: #8b8b8b;
  font-size: 12px;
  text-align: center;
}
/* 単価・価格 */
.trialBalanceSpecEditFormUnitPriceBlock {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f0eeef;
  margin-bottom: 12px;
  border-radius: 2px;
}
.trialBalanceSpecEditFormUnitPriceBlockDisabled {
  background: #f0eeef;
  border: none;
}
.trialBalanceSpecEditFormUnitPriceTitle {
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background: #1c6ecd;
  border-radius: 2px 0 0 2px;
}
.trialBalanceSpecEditFormUnitPriceDetail {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}
.trialBalanceSpecEditFormUnitPriceDetailDisabled {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  background: #f0eeef;
}
.trialBalanceSpecEditFormUnitPriceDeliveryPara {
  margin-top: 4px;
  font-size: 12px;
  color: #333333;
}
.trialBalanceSpecEditFormUnitPricePara {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  font-family: 'Arial';
}
/* -----------------------------
 * ビューワー
 ------------------------------*/
.trialBalanceSpecEditViewerBlock {
  width: 48%;
}
.trialBalanceSpecEditViewerInner {
  position: sticky;
  top: 144px;
}
.trialBalanceSpecEditViewerArea {
  position: relative;
}
.trialBalanceSpecEditViewerArea img {
  width: 100%;
}
.trialBalanceSpecEditViewerUi {
  width: 332px;
  position: absolute;
  top: 12px;
  right: 12px;
}
.trialBalanceSpecEditViewerUi img {
  width: 100%;
}
.trialBalanceSpecEditViewerBtnBlock {
  position: absolute;
  bottom: 0;
}
.trialBalanceSpecEditViewerBtnThumb,
.trialBalanceSpecEditViewerBtnCapture {
  display: block;
  font-size: 12px;
  color: #1c6ecd;
  text-decoration: underline;
}
.trialBalanceSpecEditViewerBtnThumb:hover,
.trialBalanceSpecEditViewerBtnCapture:hover {
  opacity: 0.7;
}
.trialBalanceSpecEditViewerBtnThumb {
  position: absolute;
  left: 12px;
  bottom: 16px;
  background: url(../image/common/icon_camera_blue.svg) no-repeat 5% 50%;
  background-color: #ffffff;
}
.trialBalanceSpecEditViewerBtnCapture {
  padding-left: 20px;
  background: url(../image/common/icon_download_blue.svg) no-repeat 5% 50%;
  background-color: #ffffff;
  font-weight: bold;
}
.trialBalanceSpecEditViewerFormBlock {
  width: 100%;
  margin-top: 16px;
}
.trialBalanceSpecEditViewerFormTextarea {
  width: 60%;
  padding: 8px;
  border: 1px solid #dfe1e3;
  font-size: 13px;
  min-height: 56px;
}
/* -----------------------------
 * モーダル
 ------------------------------*/
/* 材料比較 */
.trialBalanceSpecEditModalCompareMaterial .modalWindowBlock {
  width: 50%;
  min-width: 500px;
  max-width: 800px;
}
.trialBalanceSpecEditModalCompareMaterialContents {
}
.trialBalanceSpecEditModalCompareMaterialList {
  list-style: none;
}
.trialBalanceSpecEditModalCompareMaterialListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #f0eeef;
}
.trialBalanceSpecEditModalCompareMaterialListItem:hover {
  background: #e6eef8;
}
.trialBalanceSpecEditModalCompareMaterialListItem:last-child {
  border-bottom: none;
}
.trialBalanceSpecEditModalCompareMaterialTitle {
  font-size: 14px;
  font-weight: bold;
  flex: 3;
}
.trialBalanceSpecEditModalCompareMaterialDetail {
  flex: 2;
}
.trialBalanceSpecEditModalCompareMaterialDetailPrice {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Arial';
  text-align: right;
}
.trialBalanceSpecEditModalCompareMaterialDelivery {
  font-size: 12px;
  text-align: right;
}
.trialBalanceSpecEditModalCompareMaterialDetailLink {
  display: block;
  width: 70px;
  height: 28px;
  margin: 0 0 0 20px;
  padding: 0 8px;
  background: #ffffff;
  color: #1c6ecd;
  font-size: 12px;
  text-decoration: none;
  text-align: center;
  line-height: 28px;
  border: 1px solid #1c6ecd;
  border-radius: 30px;
}
/* 材料詳細 */
.trialBalanceSpecEditModalMaterialDetail .modalWindowBlock {
  width: 50%;
  min-width: 500px;
  max-width: 800px;
}
.trialBalanceSpecEditModalMaterialDetail .modalWindowContents {
  padding: 24px;
}
.trialBalanceSpecEditModalMaterialDetailTitle {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
  color: #1c6ecd;
}
.trialBalanceSpecEditModalMaterialDetailBlock {
  display: flex;
  justify-content: space-between;
}
.trialBalanceSpecEditModalMaterialDetailImage {
  width: 236px;
  margin-right: 24px;
}
.trialBalanceSpecEditModalMaterialDetails {
}
.trialBalanceSpecEditModalMaterialDetailSubTitle {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: bold;
}
.trialBalanceSpecEditModalMaterialDetailPara {
  font-size: 14px;
  line-height: 1.6em;
}
.flexModelingPostureEdit {
  /*div*/
  display: flex;
  flex-wrap: nowrap;
  height: 559px;
}
h2.trialBalanceSpecEditFormLabel {
  /*h2*/
  display: block;
  width: 100%;
  height: 40px;
  padding-left: 1em;
  font-size: 16px;
  font-weight: bold;
  color: #1c6ecd;
  line-height: 40px;
  background-color: #e6eef8;
  border-bottom: 2px solid #1c6ecd;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
}
.trialBalanceSpecEditFormCheckTagNG {
  /*div*/
  background: #d70a30;
}
.trialBalanceSpecEditFormCheckParaNG {
  /*p*/
  background: url('../image/common/icon_check_ng_red.svg') no-repeat 0% 8%;
}
.btnOrange {
  /*button*/
  display: inline-block;
  padding: 8px 20px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #ff8f22;
  border-radius: 24px;
  border: none;
  background-color: #ffffff;
  border: 2px solid #ff8f22;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.btnOrange:hover {
  /*button*/
  color: #ffffff;
  background-color: #ff8f22;
}
.ModelingPostureResetLink {
  /*a*/
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 24px;
  transition: all 0.5s ease-out;
}
.ModelingPostureResetLink:hover {
  /*a*/
  opacity: 0.7;
}
.ModelingPostureResetLink img {
  /*img*/
  margin-right: 4px;
}
.modelModelingPostureImageButtonBlock {
  /*div*/
  padding: 12px 24px;
}
/*指示書アップロード*/
.directionFileUploadedName {
  width: 60%;
  float: right;
}
/*試算条件*/
.fixedValue {
  width: 65%;
  font-size: 14px;
  padding: 8px 0;
}
/*カレンダー*/
.trialBalanceSpecEditFormCalendarTitle ul {
  display: flex;
  width: 100%;
  margin: 16px 0 12px;
}
.trialBalanceSpecEditFormCalendarTitle .calendarSelectedMonth {
  width: 60%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.trialBalanceSpecEditFormCalendarTitle .calendarPreview,
.trialBalanceSpecEditFormCalendarTitle .calendarNext {
  width: 20%;
  font-size: 14px;
}
.trialBalanceSpecEditFormCalendarTitle .calendarPreview a,
.trialBalanceSpecEditFormCalendarTitle .calendarNext a {
  text-decoration: none;
  vertical-align: sub;
}
.trialBalanceSpecEditFormCalendarTitle .calendarPreview a:visited {
  color: #888;
}
.trialBalanceSpecEditFormCalendarTitle .calendarNext a:link,
.trialBalanceSpecEditFormCalendarTitle .calendarNext a:visited {
  color: #333;
}
.trialBalanceSpecEditFormCalendarTitle .calendarPreview a:link,
.trialBalanceSpecEditFormCalendarTitle .calendarPreview a:disabled,
.trialBalanceSpecEditFormCalendarTitle .calendarNext a:disabled {
  text-decoration: none;
  color: #888;
}
.trialBalanceSpecEditFormCalendarTitle .calendarPreview {
  background: url('../image/common/Icon_arrow_preview.svg') no-repeat 0% 50%;
  padding-left: 16px;
  color: #888;
}
.trialBalanceSpecEditFormCalendarTitle .calendarNext {
  background: url('../image/common/Icon_arrow_next.svg') no-repeat 100% 50%;
  padding-right: 16px;
  color: #333;
  text-align: right;
}
.trialBalanceSpecEditFormToggleContentBlock table {
  width: 100%;
  border: 1px solid #f0eeef;
  table-layout: fixed;
  font-size: 14px;
}
.trialBalanceSpecEditFormToggleContentBlock table th,
.trialBalanceSpecEditFormToggleContentBlock table td {
  height: 48px;
  text-align: center;
  border: 1px solid #f0eeef;
}
.trialBalanceSpecEditFormToggleContentBlock table th {
  background-color: #eceeef;
}
.trialBalanceSpecEditFormToggleContentBlock table th.saturday {
  background-color: #c5e7f8;
  color: #048dd1;
}
.trialBalanceSpecEditFormToggleContentBlock table th.sunday {
  background-color: #f5cec9;
  color: #d51c04;
}
.trialBalanceSpecEditFormToggleContentBlock table td.saturday {
  background-color: #e7f5fc;
}
.trialBalanceSpecEditFormToggleContentBlock table td.sunday {
  background-color: #fae9e7;
}
.trialBalanceSpecEditFormToggleContentBlock table td.today {
  background-color: #fcf4ec;
  border: #ff8f22 2px solid;
}
.trialBalanceSpecEditFormToggleContentBlock table td.today span {
  color: #888;
}
.trialBalanceSpecEditFormToggleContentBlock table td.expressDelivery {
  background-color: #fdf8e2;
}
.trialBalanceSpecEditFormToggleContentBlock table td.calendarDisabledText {
  color: #888;
}
.trialBalanceSpecEditFormCalendarExplanation {
  margin-top: 8px;
}
.trialBalanceSpecEditFormCalendarExplanation ul {
  display: flex;
  font-size: 12px;
}
.trialBalanceSpecEditFormCalendarExplanation ul li {
  margin-right: 24px;
  display: flex;
}
.telExplanationBox {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #fcf4ec;
  border: #ff8f22 2px solid;
  margin-right: 8px;
}
.expressfeeExplanationBox {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #fdf8e2;
  border: #f0eeef 1px solid;
  margin-right: 8px;
}
.trialBalanceSpecEditFormCalendarResult {
  margin-top: 24px;
}
.trialBalanceSpecEditFormCalendarResult ul {
  display: flex;
  flex-wrap: wrap;
}
.trialBalanceSpecEditFormCalendarResult ul li {
  display: flex;
  margin-right: 32px;
  flex-grow: 1;
}
.trialBalanceSpecEditFormCalendarResult .resultForm {
  width: 100%;
  border: 1px solid #f0eeef;
  font-size: 14px;
  border-radius: 2px;
  padding: 8px;
}
.trialBalanceSpecEditFormCalendarResult .resultFormDisabled {
  width: 100%;
  border: 1px solid #f0eeef;
  font-size: 14px;
  border-radius: 2px;
  padding: 8px;
  background: #f0eeef;
}
.trialBalanceSpecEditFormCalendarResult label {
  font-weight: bold;
}
/*ページング*/
.trialBalanceSpecEditPagingBlock {
  display: flex;
  margin: 12px 0;
  font-size: 13px;
  color: #333333;
  justify-content: center;
}

/*試算仕様 型番追加*/
.addModelNumberContents {
  width: 930px;
  height: 410px;
  overflow: auto;
  overflow-x: hidden;
}
.addModelNumberContents .modelNumberListTable {
  width: 100%;
  background: #fff;
  border-collapse: collapse;
}
.addModelNumberContents .modelNumberListTable thead {
  border-bottom: 2px solid #1c6ecd;
  background: #e6eef8;
}
.addModelNumberContents .modelNumberListTable tr {
  border-bottom: 1px solid #dfe1e3;
}
.addModelNumberContents .modelNumberListTable th {
  height: 42px;
  padding: 0 8px;
  font-size: 13px;
  color: #333333;
  font-weight: bold;
  white-space: nowrap;
  text-align: left;
  border-left: 1px solid #f5f5f6;
}
.addModelNumberContents .modelNumberListTable td {
  padding: 12px 8px;
  font-size: 14px;
  color: #333333;
}
.addModelNumberContents .modelNumberListTableItemThumb {
  display: flex;
  justify-content: center;
}
.addModelNumberContents .modelNumberListTableItemFilename {
  max-width: 10em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.addModelNumberContents .modelNumberListTableItemFilename {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.addModelNumberContents .modelNumberListTableItemLabelname {
  max-width: 8em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.addModelNumberContents .modelNumberListTableItemModelname {
  max-width: 5em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.addModelNumberContents .modelNumberListTableItemPrice,
.addModelNumberContents .modelNumberListTableItemDelivery {
  font-size: 13px;
}
.addModelNumberContents .modelNumberListTableItemStatus {
  color: #333333;
}
.addModelNumberContents .modelNumberListTableItemDate {
  font-size: 12px;
  color: #707070;
}
.addModelNumberContents .modelNumberListSortLink {
  display: inline-block;
  width: 5px;
  height: 10px;
  margin-left: 8px;
}
.addModelNumberContents .modelNumberListSortLinkAscending {
  background: url(../image/common/icon_sort_ascending.svg) no-repeat top left;
}
.addModelNumberContents .modelNumberListSortLinkDefalt {
  background: url(../image/common/icon_sort_defalt.svg) no-repeat top left;
}
.addModelNumberContents .modelNumberListTableItemCheckbtn {
  display: block;
  margin: 0 auto;
  padding: 4px 8px;
  font-size: 12px;
  color: #1c6ecd;
  text-align: center;
  text-decoration: none;
  border: 1px solid #1c6ecd;
  border-radius: 30px;
  white-space: nowrap;
  background: #ffffff;
  transition: all 0.5s ease-out;
}
.addModelNumberContents .modelNumberListTableItemCheckbtn:hover {
  color: #ffffff;
  background: #1c6ecd;
}
.addModelNumberContents .modelNumberListPagingBlock {
  display: flex;
  margin: 12px 0;
  font-size: 13px;
  color: #333333;
  justify-content: flex-end;
}
.addModelNumberContentsmodelNumberListPagingBlock {
  display: flex;
  padding: 16px 16px 4px;
  border-top: 1px solid #f0eeef;
  font-size: 13px;
  color: #333333;
  justify-content: flex-end;
}

/*フォーム確認画面*/
.pageTitleDetailText {
  margin: 40px 0;
  color: #333;
  font-size: 14px;
}

table.trialBalanceAddressConfirmation {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #333;
}

table.trialBalanceAddressConfirmation th {
  width: 152px;
}

table.trialBalanceAddressConfirmation th,
table.trialBalanceAddressConfirmation td {
  border-bottom: 1px solid #f0eeef;
  height: 24px;
  padding: 20px 24px;
}

a.changeShippingInfoLink {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  margin: 32px 0;
  padding-left: 16px;
  color: #1c6ecd;
  background: url('../image/common/icon_edit_blue.svg') no-repeat 0 50%;
  transition: all 0.5s ease-out;
}
a.changeShippingInfoLink:hover {
  opacity: 0.7;
}
.trialBalanceButtonBlock {
  display: flex;
  justify-content: space-between;
}

.contactPerson,
.formalQuoteRequest {
  width: 49%;
  text-align: center;
  color: #333;
  padding: 24px 0;
  font-size: 14px;
}
.contactPerson {
  background-color: #f8fbff;
}

.formalQuoteRequest {
  background-color: #fffaf2;
}

.contactPerson h3,
.formalQuoteRequest h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
.btnContactPerson {
  display: inline-block;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #1c6ecd;
  border-radius: 24px;
  background-color: #fff;
  border: 2px solid #1c6ecd;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  min-width: 200px;
  margin-top: 16px;
}
.btnContactPerson:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  background-color: #1c6ecd;
  min-width: 200px;
}

.formalQuoteRequest select {
  height: 40px;
  max-width: 230px;
}
.formalQuoteRequest .btnOrangeCommit {
  margin-top: 16px;
}