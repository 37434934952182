.modalMailEditTitle {
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.modalMailEditTitleExp {
  color: #333;
  font-size: 14px;
  margin-bottom: 10px;
}

.modalMailEdit {
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  padding: 50px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.modalMailEditContent {
  display: flex;
}

.modalMailEditLabel {
  padding: 20px 24px 20px 24px;
  background: #e6eef8;
  border-left: 2px solid #1c6ecd;
  border-bottom: 1px solid #fff;
  min-width: 200px;
}

.modalMailEditLabel label {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

.modalMailEditInput {
  padding: 12px 10px 12px 24px;
  width: 100%;
  background: #f8fbff;
  border-bottom: 1px dotted#F0EEEF;
  min-width: 400px;
}

.modalMailEditInput input {
  width: 90%;
}

.modalMailEditValue {
  padding: 20px 10px 20px 24px;
  width: 100%;
  background: #f8fbff;
  border-bottom: 1px dotted#F0EEEF;
  min-width: 400px;
}

.modalMailEditButton {
  margin: 0 auto;
  padding: 4px 8px;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  border: 1px solid #1c6ecd;
  border-radius: 30px;
  white-space: nowrap;
  background: #1c6ecd;
  transition: all 0.5s ease-out;
}

.modalMailEditButton:hover {
  color: #1c6ecd;
  background: #ffffff;
  border: 1px solid #1c6ecd;
}

.modalMailEditButton:disabled {
  color: #8b8b8b;
  background-color: #f0eeef;
  border: 2px solid #f0eeef;
}

.modalMailEditError {
  color: #d9534f;
  font-size: 14px;
  margin-top: 5px;
  white-space: pre-line;
}

.modalMailEditCloseBtn {
  text-align: center;
}
.modalMailEditCloseBtn button {
  margin-top: 20px;
}
