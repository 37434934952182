/* -------------------------------------
 *
 * reset
 *
------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: 1em;
  font-weight: normal;
}

p {
  margin: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

dl,
dd {
  margin: 0;
}

small {
  font-size: 100%;
}

em {
  font-style: normal;
}
