@charset "UTF-8";
/* -------------------------------------
 * quotationRequest.css
 * 正式見積依頼ページ用
------------------------------------- */
.pageTitleDetailText{
  margin:40px 0;
  color: #333;
  font-size: 14px;
}

table.quotationRequestAddressConfirmation{
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #333;
}

table.quotationRequestAddressConfirmation th{
  width:152px;
}

table.quotationRequestAddressConfirmation th,table.quotationRequestAddressConfirmation td{
  border-bottom: 1px solid #F0EEEF;
  height: 24px;
  padding: 20px 24px;
}

a.changeShippingInfoLink{
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  margin: 32px 0;
  padding-left: 16px;
  color: #1C6ECD;
  background: url("../image/common/icon_edit_blue.svg") no-repeat 0 50%;
  transition: all .5s ease-out;
}
a.changeShippingInfoLink:hover{
  opacity: 0.7;
}
.quotationRequestButtonBlock{
  display: flex;
  justify-content: space-between;
}

.contactPerson,.formalQuoteRequest{
  width: 49%;
  text-align: center;
  color: #333;
  padding: 24px 0;
  font-size: 14px;
}
.contactPerson{
  background-color: #F8FBFF;
}

.formalQuoteRequest{
  background-color: #FFFAF2;
}

.contactPerson h3,.formalQuoteRequest h3{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
.btnContactPerson {
  display: inline-block;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #1C6ECD;
  border-radius: 24px;
  background-color: #fff;
  border: 2px solid #1C6ECD;
  box-sizing: border-box;
  transition: all .5s ease-out;
  min-width: 200px;
  margin-top: 16px;
}
.btnContactPerson:hover{
  color: #fff;
    background-color: rgba(0, 0, 0, 0);
  background-color: #1C6ECD;
  min-width: 200px;
}

.formalQuoteRequest select{
    height: 40px;
    max-width: 230px;
}
.formalQuoteRequest .btnOrangeCommit{
  margin-top: 16px;
}

/*再アップロードエリア*/
.modelReuploadArea{
  border-top: 1px solid #F0EEEF;
  border-bottom: 1px solid #F0EEEF;
  padding: 32px 0;
  color: #333;
  font-size: 14px;
  margin-bottom: 24px;
}

.modelReuploadArea .btnContactPerson {
  display: inline-block;
  height: 32px;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  color: #1C6ECD;
  border-radius: 24px;
  background-color: #fff;
  border: 2px solid #1C6ECD;
  box-sizing: border-box;
  transition: all .5s ease-out;
  min-width: 160px;
  margin-top: 8px;
  margin-bottom: 24px;
}
.modelReuploadArea .btnContactPerson:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  background-color: #1C6ECD;
  min-width: 160px;
}
.modelReuploadData{
  background: url("../image/common/icon_check_ok_green.svg") no-repeat 0 50%;
  padding-left: 20px;
  font-weight: bold;
}
.btnBack {
  display: inline-block;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #8B8B8B;
  border-radius: 24px;
  background-color: #fff;
  border: 2px solid #8B8B8B;
  box-sizing: border-box;
  transition: all .5s ease-out;
  min-width: 160px;
  margin-top: 16px;
  margin-right: 16px;
}
.btnBack:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  background-color: #8B8B8B;
  min-width: 160px;
}
.modelReuploadButtonArea{
}

.trialBalanceArea{
  margin-top: 40px;
    background: #F8FBFF;
    border: 1px solid #F0EEEF;
  width: 100%;
  height: 65vh;
  overflow: scroll;
  overflow-x: hidden;
    text-align: center;
  padding: 24px;
}


.btnOrangeDownload{
  display: inline-block;
  padding: 8px 19px 8px 35px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border-radius: 24px;
  border:none;
  background: url("../image/common/icon_Download_white.svg") no-repeat 12% 50%;
  background-color: #FF8F22;
  border: 2px solid #FF8F22;
  box-sizing: border-box;
  transition: all .5s ease-out;
}
.btnOrangeDownload:hover{
  color: #FF8F22;
  background-color: #ffffff;
  background: url("../image/common/icon_Download_orange.svg") no-repeat 12% 50%;
}
.btnOrangeDownload img{
    margin-right: 4px;
    vertical-align: middle;
}
.btnOrangeDownload:disabled{
    color: #8B8B8B;
    border: 2px solid #F0EEEF;
    box-sizing: border-box;
    background: #F0EEEF url("../image/common/icon_Download_gray.svg") no-repeat 12% 50%;
}




