.button-style {
  border-style: none;
}
.button-style-trans {
  border-style: none;
  background-color: transparent;
}
.select-form:disabled {
  background-color: #e1e2e2;
  color: #636d79;
}
.formHelpInfomationDetail2 {
  /* position: absolute; */
  /* right: -200px; */
  /* top: 0; */
  width: 200px;
  padding: 8px;
  background: #0056ba;
  border-radius: 4px;
  opacity: 0.95;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
    rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
}

.modelNoSpecEditFormSubInfoBlock2 {
  /* position: absolute; */
  width: 300px;
  background: rgba(0, 86, 186, 0.9);
  /* visibility: hidden; */
}

.eventCalendarSelect {
  background-color: #ff8f22 !important;
}

.eventCalendarGray {
  background-color: #d7d7d7 !important;
}

.eventCalendarPhone {
  border: #7e7e7e 2px solid;
  opacity: 1 !important;
  background-color: transparent !important;
  background: repeating-linear-gradient(
    -45deg,
    #7e7e7e,
    #7e7e7e 1.5px,
    transparent 0,
    transparent 10px
  ) !important;
}

.eventCalendarExpress {
  border: #ec3717 2px solid;
  opacity: 1 !important;
  background-color: transparent !important;
}

.eventCalendarNormal {
  border: #14d314 2px solid;
  opacity: 1 !important;
  background-color: transparent !important;
}

.eventCalendarHoliday {
  background-color: rgba(255, 234, 234, 0.7) !important;
  opacity: 1 !important;
}

.normalfeeExplanationBox {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: #14d314 2px solid;
  margin-right: 8px;
}

.telExplanationBox2 {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: #7e7e7e 2px solid;
  margin-right: 8px;
  background: repeating-linear-gradient(-45deg, #7e7e7e, #7e7e7e 1.5px, #fff 0, #fff 5px);
}
.expressfeeExplanationBox2 {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: #ec3717 2px solid;
  margin-right: 8px;
}

.modalProfile {
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  overflow-y: auto;
  padding: 50px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
