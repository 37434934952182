@media screen and (max-width: 1024px) {
  .footerInner {
    width: 100%;
    min-width: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0 0 10px 0;
  }

  .footerMenuList {
    display: block;
  }
}
