.woot--bubble-holder .woot-widget-bubble.woot-widget--expanded {
  display: block;
  width: 90px !important;
  height: 90px !important;
  background: url(../image/common/icon_chat.png) no-repeat center 16px !important;
  border-radius: 90px !important;
  background-color: #00b862 !important;
  border: 2px solid #00b862 !important;
  box-sizing: border-box !important;
  transition: all 0.5s ease-out !important;
}
.woot--bubble-holder .woot-widget-bubble.woot-widget--expanded img {
  display: none;
}
.woot--bubble-holder .woot-widget-bubble.woot-widget--expanded div {
  padding-right: 0px;
  padding-top: 30px;
  font-size: 12px !important;
  line-height: 10px;
}
.woot-widget-bubble.woot-widget--expanded svg {
  display: none;
}
